var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import Skeleton from "./Skeleton";
import { FailNotification } from "../utils/NotificationEvent";
var AvatarNew = /** @class */ (function (_super) {
    __extends(AvatarNew, _super);
    function AvatarNew() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isAvatarLoaded: false
        };
        _this.onAvatarLoaded = function () {
            _this.setState({ isAvatarLoaded: true });
        };
        _this.onAvatarLoadFail = function () {
            FailNotification("Avatar failed to load.");
        };
        return _this;
    }
    AvatarNew.prototype.render = function () {
        var user = this.props.user;
        return (React.createElement("section", { className: "avatarNew" },
            React.createElement("div", { className: "avatarNew__img" },
                user && (React.createElement("img", { className: "img-fluid", src: "".concat(user.AvatarUrl, "?s=300"), onLoad: this.onAvatarLoaded, onError: this.onAvatarLoadFail, style: { display: this.onAvatarLoaded ? "block" : "none" } })),
                !this.state.isAvatarLoaded && React.createElement(Skeleton, { class: "image" })),
            React.createElement("div", null,
                React.createElement("h1", { className: "avatarNew__name" }, user ? user.Name : React.createElement(Skeleton, null)),
                React.createElement("p", { className: "avatarNew__email" }, user ? user.Email : React.createElement(Skeleton, null)))));
    };
    return AvatarNew;
}(React.PureComponent));
export default AvatarNew;
