var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { AxiosStrict, AxiosFlexible } from "../utils/Axios";
import ApiRoutes from "../utils/ApiRoutes";
import RouteBuilder from "../utils/RouteBuilder";
import { Loading, Participants } from "../components";
import { SuccessNotification, FailNotification } from "../utils/NotificationEvent";
import { Link } from "react-router-dom";
var ProjectVoting = /** @class */ (function (_super) {
    __extends(ProjectVoting, _super);
    function ProjectVoting() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            details: null,
            votedFor: null
        };
        _this.toggleVote = function () {
            var votedFor = _this.state.votedFor;
            var url = RouteBuilder(ApiRoutes.UserVote, _this.params.orgId, _this.params.eventId, _this.params.projectId);
            if (!votedFor) {
                AxiosFlexible()
                    .post(url, {})
                    .then(function (res) {
                    _this.setState({ votedFor: !votedFor });
                    SuccessNotification("Your vote has been cast!");
                })
                    .catch(function (err) {
                    if (err.response.status === 400) {
                        FailNotification(err.response.data.Message);
                    }
                    else {
                        FailNotification("Something went wrong.");
                    }
                });
            }
            else {
                AxiosFlexible()
                    .delete(url)
                    .then(function (res) {
                    _this.setState({ votedFor: !votedFor });
                    SuccessNotification("Your vote has been removed!");
                })
                    .catch(function () { return FailNotification("Something went wrong."); });
            }
        };
        return _this;
    }
    Object.defineProperty(ProjectVoting.prototype, "params", {
        get: function () {
            return this.props.match.params;
        },
        enumerable: false,
        configurable: true
    });
    ProjectVoting.prototype.componentDidMount = function () {
        document.title = "Vote | Hackathon Portal";
        this.fetchProjectData();
        this.fetchVotingData();
    };
    ProjectVoting.prototype.fetchProjectData = function () {
        var _this = this;
        AxiosStrict()
            .get(RouteBuilder(ApiRoutes.Projects, this.params.orgId, this.params.eventId, this.params.projectId))
            .then(function (res) {
            switch (res.status) {
                case 200:
                    document.title = "Vote for ".concat(res.data.Project.Name, " | Hackathon Portal");
                    _this.setState({ details: res.data.Project });
                    break;
                default:
                    _this.props.history.push("/error/".concat(res.status, "/?from=").concat(window.location.href));
                    break;
            }
        });
    };
    ProjectVoting.prototype.fetchVotingData = function () {
        var _this = this;
        AxiosFlexible()
            .get(ApiRoutes.UserVote)
            .then(function (res) {
            if (res.status === 200) {
                var exists = res.data.Votes.findIndex(function (v) { return v.Id.toLowerCase() === _this.params.projectId.toLowerCase(); });
                _this.setState({ votedFor: exists > -1 });
            }
        })
            .catch(function (err) { });
    };
    ProjectVoting.prototype.render = function () {
        var _a = this.state, details = _a.details, votedFor = _a.votedFor;
        if (details === null || votedFor === null) {
            return React.createElement(Loading, null);
        }
        return (React.createElement("div", { className: "container text-center" },
            React.createElement("div", { className: "mb-5 text-right" },
                React.createElement(Link, { className: "btn btn-light", to: RouteBuilder("", this.params.orgId, this.params.eventId, this.params.projectId) }, "Back to project")),
            "Cast your vote for:",
            React.createElement("h1", { className: "h1" }, details.Name),
            React.createElement("p", { className: "lead" }, details.Description),
            React.createElement("div", { className: "newVoteBtn mt-5 mb-5 ".concat(votedFor ? "newVoteBtn--voted" : ""), onClick: this.toggleVote }, !votedFor ? "Vote Now" : "Remove Your Vote"),
            React.createElement("h4", { className: "h4 mt-5" }, "Team Participants"),
            React.createElement(Participants, { introMessage: "", emptyMessage: "", showRole: true, participants: details.Participants })));
    };
    return ProjectVoting;
}(React.PureComponent));
export default ProjectVoting;
