var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { PageHeader, MarkdownEditor, DeleteConfirm } from "../components";
import RouteBuilder from "../utils/RouteBuilder";
import { ProjectJoinOptions } from "../models";
import ApiRoutes from "../utils/ApiRoutes";
import { FailNotification, SuccessNotification } from "../utils/NotificationEvent";
import { ShowMediaLibrary } from "../utils/MediaLibraryEvent";
import { AxiosStrict, AxiosFlexible } from "../utils/Axios";
import { MediaType } from "../models/Media";
var ProjectEditor = /** @class */ (function (_super) {
    __extends(ProjectEditor, _super);
    function ProjectEditor() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            Id: "",
            OrganisationId: "",
            EventId: "",
            Name: "",
            JoinOption: null,
            LogoUrl: "",
            Content: "",
            Description: "",
            Participants: null,
            Status: null
        };
        _this.handleSubmit = function (e) {
            var isNew = _this.props.isNew;
            e.preventDefault();
            if (!_this.isValid)
                return;
            var payload = {
                name: _this.state.Name,
                logo: _this.state.LogoUrl,
                content: _this.state.Content,
                description: _this.state.Description,
                joinoption: _this.state.JoinOption,
                status: _this.state.Status
            };
            var url = isNew
                ? "".concat(RouteBuilder(ApiRoutes.Events, _this.params.orgId, _this.params.eventId), "projects")
                : RouteBuilder(ApiRoutes.Projects, _this.params.orgId, _this.params.eventId, _this.params.projectId);
            var request = isNew ? AxiosFlexible().post : AxiosFlexible().put;
            request(url, payload)
                .then(function (res) {
                if (res.status === 200) {
                    var data = res.data.Project;
                    if (isNew) {
                        SuccessNotification("Project has been created.");
                    }
                    else {
                        SuccessNotification("Project has been updated.");
                    }
                    _this.props.history.push("".concat(RouteBuilder("", data.OrganisationId, data.EventId, data.Id)));
                }
            })
                .catch(function (err) {
                _this.props.isNew ? FailNotification("Error creating project.") : FailNotification("Error updating project.");
            });
        };
        _this.delete = function () {
            AxiosFlexible()
                .delete(RouteBuilder(ApiRoutes.Projects, _this.params.orgId, _this.params.eventId, _this.params.projectId))
                .then(function () {
                SuccessNotification("Project has been deleted.");
                _this.props.history.push(RouteBuilder("", _this.params.orgId, _this.params.eventId));
            })
                .catch(function () { return FailNotification("Error deleting project."); });
        };
        return _this;
    }
    Object.defineProperty(ProjectEditor.prototype, "params", {
        get: function () {
            return this.props.match.params;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProjectEditor.prototype, "isValid", {
        get: function () {
            var _a = this.state, Name = _a.Name, Content = _a.Content;
            var isValid = true;
            // if (!Name.trim().length || !Content.trim().length) {
            if (!Name.trim().length) {
                isValid = false;
            }
            return isValid;
        },
        enumerable: false,
        configurable: true
    });
    ProjectEditor.prototype.componentDidMount = function () {
        if (!this.props.isNew) {
            this.fetchProjectData();
            document.title = "Edit Project | Hackathon Portal";
        }
        else {
            document.title = "Create Project | Hackathon Portal";
        }
    };
    ProjectEditor.prototype.fetchProjectData = function () {
        var _this = this;
        AxiosStrict()
            .get(RouteBuilder(ApiRoutes.Projects, this.params.orgId, this.params.eventId, this.params.projectId))
            .then(function (res) {
            if (res.status === 200) {
                var details = res.data.Project;
                _this.setState({
                    Name: details.Name || "",
                    LogoUrl: details.LogoUrl || "",
                    Content: details.Content || "",
                    Description: details.Description || "",
                    JoinOption: details.JoinOption || ProjectJoinOptions.Anybody
                });
            }
        });
    };
    ProjectEditor.prototype.render = function () {
        var _this = this;
        var isNew = this.props.isNew;
        var _a = this.state, Name = _a.Name, LogoUrl = _a.LogoUrl, Content = _a.Content, Description = _a.Description, JoinOption = _a.JoinOption, Status = _a.Status;
        return (React.createElement("div", null,
            React.createElement(PageHeader, { title: isNew ? "Create a project" : "Edit project", orgId: this.params.orgId, eventId: this.params.eventId }),
            React.createElement("div", { className: "container" },
                React.createElement("form", { onSubmit: this.handleSubmit },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "form-group col-12 col-sm-6" },
                            React.createElement("label", { htmlFor: "Name" }, "Name:"),
                            React.createElement("input", { className: "form-control", type: "text", name: "Name", value: Name, onChange: function (e) {
                                    _this.setState({ Name: e.target.value });
                                } }),
                            React.createElement("small", { className: "form-text text-muted" }, "Your project name.")),
                        React.createElement("div", { className: "form-group col-12 col-sm-6" },
                            React.createElement("label", { htmlFor: "LogoUrl" }, "Logo:"),
                            React.createElement("input", { className: "form-control", type: "text", name: "LogoUrl", value: LogoUrl, placeholder: "E.g. https://placehold.it/100x100", onChange: function (e) {
                                    _this.setState({ LogoUrl: e.target.value });
                                } }),
                            React.createElement("div", { className: "float-right" },
                                React.createElement("div", { className: "btn btn-info btn-sm mt-1", onClick: function (e) {
                                        e.preventDefault();
                                        ShowMediaLibrary(function (res) { return res && _this.setState({ LogoUrl: res.Link }); }, MediaType.Image, false);
                                    } }, "Upload Logo")),
                            React.createElement("small", { className: "form-text text-muted" }, "Insert a complete URL."))),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "form-group col-12" },
                            React.createElement("label", { htmlFor: "Description" }, "Description:"),
                            React.createElement("input", { className: "form-control", type: "text", name: "Description", value: Description, onChange: function (e) {
                                    _this.setState({ Description: e.target.value });
                                } }),
                            React.createElement("small", { className: "form-text text-muted" },
                                "Your project description. ",
                                React.createElement("em", null, "Short and sweet.")))),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "form-group col-12 col-md-6 col-lg-4" },
                            React.createElement("label", { htmlFor: "JoinOption" }, "Who can join your project:"),
                            React.createElement("select", { className: "form-control", name: "JoinOption", value: JoinOption !== null ? JoinOption : "", onChange: function (e) { return _this.setState({ JoinOption: ProjectJoinOptions[e.target.value] }); } }, Object.keys(ProjectJoinOptions).map(function (s) { return (React.createElement("option", { value: s, key: s }, s.match(/[A-Z][a-z]+/g).join(" "))); })))),
                    React.createElement("h4", { className: "h4 mt-5" }, "Project Overview"),
                    React.createElement(MarkdownEditor, { content: Content, onContentUpdate: function (Content) { return _this.setState({ Content: Content }); } }),
                    React.createElement("div", { className: "mt-5 mb-5 d-flex justify-content-between" },
                        React.createElement("div", null, !isNew && React.createElement(DeleteConfirm, { onConfirm: this.delete })),
                        React.createElement("div", null,
                            React.createElement("button", { className: "btn btn-success", type: "submit", disabled: !this.isValid }, "Save"),
                            isNew && (React.createElement("a", { href: "#", onClick: function (e) {
                                    e.preventDefault();
                                    _this.props.history.push("".concat(RouteBuilder("", _this.params.orgId, _this.params.eventId)));
                                }, className: "btn btn-text" }, "Back")),
                            !isNew && (React.createElement("a", { href: "#", onClick: function (e) {
                                    e.preventDefault();
                                    _this.props.history.push("".concat(RouteBuilder("", _this.params.orgId, _this.params.eventId, _this.params.projectId)));
                                }, className: "btn btn-text" }, "Back"))))))));
    };
    return ProjectEditor;
}(React.PureComponent));
export default ProjectEditor;
