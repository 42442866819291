var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { ShirtSizes } from "../models";
import { Avatar, Loading, PageHeader } from "../components";
import ApiRoutes from "../utils/ApiRoutes";
import { AxiosFlexible } from "../utils/Axios";
import { SuccessNotification, FailNotification, InfoNotification } from "../utils/NotificationEvent";
import { ShowMediaLibrary } from "../utils/MediaLibraryEvent";
import { MediaType } from "../models/Media";
var Profile = /** @class */ (function (_super) {
    __extends(Profile, _super);
    function Profile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            Name: "",
            Email: "",
            AvatarUrl: "",
            ShirtSize: null,
            DietaryRequirements: null,
            Allergies: null,
            Since: ""
        };
        _this.resetForm = function (e) {
            e.preventDefault();
            _this.setStateFromProps();
            InfoNotification("Changes have been discarded.");
            _this.props.history.push("/dashboard/");
        };
        _this.handleSubmit = function (e) {
            e.preventDefault();
            var _a = _this.state, Name = _a.Name, AvatarUrl = _a.AvatarUrl, Email = _a.Email, DietaryRequirements = _a.DietaryRequirements, ShirtSize = _a.ShirtSize, Allergies = _a.Allergies;
            var payload = {
                name: Name,
                avatar: AvatarUrl,
                shirt_size: ShirtSize,
                dietary_requirements: DietaryRequirements,
                allergies: Allergies
            };
            AxiosFlexible()
                .put(ApiRoutes.UserDetails, payload)
                .then(function (res) {
                if (res.status === 200) {
                    SuccessNotification("Profile has been updated.");
                    _this.props.history.push("/dashboard/");
                    _this.props.updateUser();
                }
            })
                .catch(function (err) {
                FailNotification("Error saving profile.");
            });
        };
        return _this;
    }
    Profile.prototype.componentDidMount = function () {
        document.title = "Edit Profile | Hackathon Portal";
        if (this.props.user) {
            this.setStateFromProps();
        }
    };
    Profile.prototype.componentWillReceiveProps = function (props) {
        this.setStateFromProps(props);
    };
    Profile.prototype.setStateFromProps = function (props) {
        if (props === void 0) { props = this.props; }
        props.user && this.setState(props.user);
    };
    Profile.prototype.render = function () {
        var _this = this;
        if (!this.props.user) {
            return React.createElement(Loading, null);
        }
        var _a = this.state, Name = _a.Name, AvatarUrl = _a.AvatarUrl, Email = _a.Email, DietaryRequirements = _a.DietaryRequirements, ShirtSize = _a.ShirtSize, Allergies = _a.Allergies;
        return (React.createElement("div", null,
            React.createElement(PageHeader, { title: "Edit Profile" }),
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12 col-sm-8 col-lg-9" },
                        React.createElement("form", { onSubmit: this.handleSubmit },
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "form-group col-12 col-lg-6" },
                                    React.createElement("label", { htmlFor: "Name" }, "Name:"),
                                    React.createElement("input", { className: "form-control", type: "text", name: "Name", value: Name, onChange: function (e) { return _this.setState({ Name: e.target.value }); } }),
                                    React.createElement("small", { className: "form-text text-muted" }, "Your full name.")),
                                React.createElement("div", { className: "form-group col-12 col-lg-6" },
                                    React.createElement("label", { htmlFor: "Email" }, "Email:"),
                                    React.createElement("input", { className: "form-control", type: "email", name: "Email", defaultValue: Email, disabled: true }),
                                    React.createElement("small", { className: "form-text text-muted" }, "Cannot be changed."))),
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "form-group col-12" },
                                    React.createElement("label", { htmlFor: "AvatarUrl" }, "Profile Picture:"),
                                    React.createElement("input", { className: "form-control", type: "text", name: "AvatarUrl", value: AvatarUrl, onChange: function (e) { return _this.setState({ AvatarUrl: e.target.value }); } }),
                                    React.createElement("div", { className: "float-right" },
                                        React.createElement("div", { className: "btn btn-info btn-sm mt-1", onClick: function (e) {
                                                e.preventDefault();
                                                ShowMediaLibrary(function (res) { return res && _this.setState({ AvatarUrl: res.Link }); }, MediaType.Image, false);
                                            } }, "Upload Image")),
                                    React.createElement("small", { className: "form-text text-muted" }, "Insert a complete URL."))),
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "form-group col-12 col-md-6 col-lg-4" },
                                    React.createElement("label", { htmlFor: "ShirtSize" }, "Shirt Size:"),
                                    React.createElement("select", { className: "form-control", name: "ShirtSize", value: ShirtSize !== null ? ShirtSize : "", onChange: function (e) { return _this.setState({ ShirtSize: ShirtSizes[e.target.value] }); } },
                                        ShirtSize === null && (React.createElement("option", { value: "", disabled: true }, "Select")),
                                        Object.keys(ShirtSizes).map(function (s) { return (React.createElement("option", { value: s, key: s }, s)); }))),
                                React.createElement("div", { className: "form-group col-12 col-md-6 col-lg-4" },
                                    React.createElement("label", { htmlFor: "DietaryRequirements" }, "Dietary Requirements:"),
                                    React.createElement("input", { className: "form-control", type: "text", name: "DietaryRequirements", value: DietaryRequirements !== null ? DietaryRequirements : "", onChange: function (e) { return _this.setState({ DietaryRequirements: e.target.value }); } })),
                                React.createElement("div", { className: "form-group col-12 col-md-6 col-lg-4" },
                                    React.createElement("label", { htmlFor: "Allergies" }, "Allergies:"),
                                    React.createElement("input", { className: "form-control", type: "text", name: "Allergies", value: Allergies !== null ? Allergies : "", onChange: function (e) { return _this.setState({ Allergies: e.target.value }); } }))),
                            React.createElement("div", { className: "btn-group text-right mt-5 clearfix" },
                                React.createElement("a", { href: "#", className: "btn btn-light", onClick: this.resetForm }, "Cancel"),
                                "\u00A0",
                                React.createElement("button", { className: "btn btn-success", type: "submit" }, "Save")))),
                    React.createElement("div", { className: "col-12 col-sm-4 col-lg-3" },
                        React.createElement("h4", null, "Preview"),
                        React.createElement(Avatar, { user: __assign({}, this.state) }))))));
    };
    return Profile;
}(React.PureComponent));
export default Profile;
