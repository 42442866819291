var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import ReactPlayer from "react-player";
var VideoPlayer = /** @class */ (function (_super) {
    __extends(VideoPlayer, _super);
    function VideoPlayer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isVisible: false,
            videoSource: null
        };
        return _this;
    }
    VideoPlayer.prototype.componentDidMount = function () {
        var _this = this;
        document.body.addEventListener("keydown", function (e) { return e.keyCode === 27 && _this.close(); });
        document.body.addEventListener("PlayVideo", function (e) {
            _this.setState({ videoSource: e.detail.source, isVisible: true });
        });
    };
    VideoPlayer.prototype.close = function () {
        if (!this.state.isVisible)
            return;
        this.setState({ isVisible: false, videoSource: null });
    };
    VideoPlayer.prototype.render = function () {
        var _this = this;
        var _a = this.state, isVisible = _a.isVisible, videoSource = _a.videoSource;
        if (!isVisible)
            return null;
        return (React.createElement("section", { className: "videoPlayer", onClick: function (e) { return e.target.classList.contains("videoPlayer") && _this.close(); } },
            React.createElement("div", { className: "videoPlayer__close", onClick: function () { return _this.close(); } }),
            React.createElement(ReactPlayer, { url: videoSource, controls: true, width: "50%", height: "50%", config: {
                    file: {
                        forceHLS: !(/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
                    }
                } })));
    };
    return VideoPlayer;
}(React.PureComponent));
export default VideoPlayer;
