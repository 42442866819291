var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { AvatarNew, UserProjectListNew, UserEventsListNew, UserInvitationsListNew, UserVoteListNew } from "../components";
import DashboardVideoPlayerNew from "../components/DashboardVideoPlayerNew";
var DashboardNew = /** @class */ (function (_super) {
    __extends(DashboardNew, _super);
    function DashboardNew() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {};
        return _this;
    }
    DashboardNew.prototype.componentDidMount = function () {
        document.title = "Dashboard | Hackathon Portal";
    };
    DashboardNew.prototype.render = function () {
        var user = this.props.user;
        return (React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-12 col-sm-12 col-md-12 col-lg-3" },
                    React.createElement("div", null,
                        React.createElement(AvatarNew, { user: user || null })),
                    React.createElement("div", null,
                        React.createElement("h2", { className: "h5" }, "Pending Invitations"),
                        React.createElement(UserInvitationsListNew, null))),
                React.createElement("div", { className: "col-12 col-sm-12 col-md-12 col-lg-6" },
                    React.createElement("h6", { className: "h6" }, "About Hackgorilla"),
                    React.createElement(DashboardVideoPlayerNew, null)),
                React.createElement("div", { className: "col-12 col-sm-12 col-md-12 col-lg-3" },
                    React.createElement("div", null,
                        React.createElement("h6", { className: "h6" }, "Your Projects"),
                        React.createElement(UserProjectListNew, null)),
                    React.createElement("div", null,
                        React.createElement("h6", { className: "h6" }, "You've Voted On"),
                        React.createElement(UserVoteListNew, null)))),
            React.createElement("hr", { style: { borderTop: "1px solid #E0E0E0" } }),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-12 col-sm-12 col-md-12 col-lg-6" },
                    React.createElement("h6", { className: "h6" }, "Current/Upcoming Events"),
                    React.createElement(UserEventsListNew, { status: "Upcoming" })),
                React.createElement("div", { className: "col-12 col-sm-12 col-md-12 col-lg-6" },
                    React.createElement("h6", { className: "h6" }, "Past Events"),
                    React.createElement(UserEventsListNew, { status: "Past" })))));
    };
    return DashboardNew;
}(React.PureComponent));
export default DashboardNew;
