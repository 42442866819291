import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { MarkdownRender } from ".";
import { ShowMediaLibrary } from "../utils/MediaLibraryEvent";
import { MediaType } from "../models/Media";
var MarkdownEditor = function (props) {
    var content = props.content, onContentUpdate = props.onContentUpdate;
    return (React.createElement(Tabs, null,
        React.createElement(TabList, null,
            React.createElement(Tab, null, "Edit Mode"),
            React.createElement(Tab, null, "Preview Mode"),
            React.createElement(Tab, null, "Split Mode"),
            React.createElement("div", { className: "ml-auto" },
                React.createElement("div", { className: "btn btn-info btn-sm", onClick: function (e) {
                        e.preventDefault();
                        ShowMediaLibrary(function (res) {
                            if (res) {
                                var newContent = "";
                                if (res.Type === MediaType.Image) {
                                    newContent = "![Alt Text](".concat(res.Link, ")");
                                }
                                if (res.Type === MediaType.Video) {
                                    newContent = "[![](".concat(res.Link, "?vxc_format=jpg)](").concat(res.Link, "#csHackVideoModal)");
                                }
                                onContentUpdate("".concat(content, "\n\n").concat(newContent));
                            }
                        });
                    } }, "Insert Media"))),
        React.createElement(TabPanel, null,
            React.createElement("p", null,
                React.createElement("small", { className: "form-text text-muted" },
                    "Markdown is supported, for a reference to markdown syntax",
                    " ",
                    React.createElement("a", { href: "https://www.markdownguide.org/cheat-sheet/", target: "_blank" }, "click here"),
                    ".")),
            React.createElement("textarea", { className: "form-control", name: "content", value: content, rows: 15, onChange: function (e) { return onContentUpdate(e.target.value); } })),
        React.createElement(TabPanel, null,
            React.createElement(MarkdownRender, { raw: content })),
        React.createElement(TabPanel, null,
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("p", null,
                        React.createElement("small", { className: "form-text text-muted" },
                            "Markdown is supported, for a reference to markdown syntax",
                            " ",
                            React.createElement("a", { href: "https://www.markdownguide.org/cheat-sheet/", target: "_blank" }, "click here"),
                            "."))),
                React.createElement("div", { className: "col-12 col-sm-6" },
                    React.createElement("textarea", { className: "form-control", name: "content", value: content, rows: 15, style: { height: "100%" }, onChange: function (e) { return onContentUpdate(e.target.value); } })),
                React.createElement("div", { className: "col-12 col-sm-6" },
                    React.createElement(MarkdownRender, { raw: content }))))));
};
export default MarkdownEditor;
