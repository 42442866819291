var parseUrl = function (search) {
    var params = {};
    if (search.indexOf("?") === -1)
        return params;
    var qsp = search.slice(search.indexOf("?") + 1);
    if (!qsp)
        return params;
    qsp.split("&").forEach(function (hash) {
        var _a = hash.split("="), key = _a[0], val = _a[1];
        params[key] = val && val.length > 0 ? decodeURIComponent(val) : null;
    });
    return params;
};
var get = function (key, url) {
    if (url === void 0) { url = document.location.href; }
    var queryObj = parseUrl(url);
    if (key !== null && typeof key !== "undefined" && key.length > 0) {
        return typeof queryObj[key] === "undefined" ? null : queryObj[key];
    }
    else {
        return queryObj;
    }
};
var UrlParams = {
    get: get
};
export default UrlParams;
