var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { Participants, Loading } from ".";
import { AxiosFlexible } from "../utils/Axios";
import ApiRoutes from "../utils/ApiRoutes";
import RouteBuilder from "../utils/RouteBuilder";
import { FailNotification } from "../utils/NotificationEvent";
var TeamsBreakdown = /** @class */ (function (_super) {
    __extends(TeamsBreakdown, _super);
    function TeamsBreakdown() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            data: null,
            isError: false
        };
        _this.getCSV = function () {
            var _a = _this.props, orgId = _a.orgId, eventId = _a.eventId;
            AxiosFlexible()
                .get("".concat(RouteBuilder(ApiRoutes.Events, orgId, eventId), "reports/csv/participants.csv"), {
                responseType: "blob"
            })
                .then(function (res) {
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = "participants.csv";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
                .catch(function () { return FailNotification("Oops, something went wrong."); });
        };
        return _this;
    }
    TeamsBreakdown.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props, orgId = _a.orgId, eventId = _a.eventId;
        AxiosFlexible()
            .get(RouteBuilder(ApiRoutes.Events, orgId, eventId, "reports/teams"))
            .then(function (res) {
            if (res.status === 200) {
                _this.setState({ data: res.data.Report });
            }
            else {
                _this.setState({ isError: true });
            }
        })
            .catch(function () { return _this.setState({ isError: true }); });
        // if (projects.length) {
        //   const results = projects.map(p =>
        //     getProjectByIdAndEventIdAndOrgId(orgId, eventId, p.Id).then((prj: IProject) => prj)
        //   );
        //   Promise.all(results)
        //     .then(data => this.setState({ data }))
        //     .catch(() => this.setState({ isError: true }));
        // }
    };
    TeamsBreakdown.prototype.render = function () {
        var _a = this.state, data = _a.data, isError = _a.isError;
        var _b = this.props, orgId = _b.orgId, eventId = _b.eventId;
        if (isError) {
            return (React.createElement("p", { className: "small" },
                React.createElement("em", null, "Unable to load teams breakdown.")));
        }
        if (data === null) {
            return React.createElement(Loading, { className: "loading--small", isFullScreen: false });
        }
        if (!data.Projects.length) {
            return (React.createElement("p", { className: "small" },
                React.createElement("em", null, "No teams exist for this event yet.")));
        }
        return (React.createElement("div", { className: "teamBreakdown" },
            React.createElement("p", { className: "text-right" },
                React.createElement("button", { className: "btn btn-success btn-sm", onClick: this.getCSV }, "Export CSV")),
            data.Projects.map(function (p) {
                return (React.createElement("div", { className: "teamBreakdown__team", key: p.Id },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12 col-lg-3" },
                            React.createElement("h5", { className: "h5" },
                                p.Name,
                                " ",
                                React.createElement("em", { className: "small" },
                                    "(",
                                    p.Members.length,
                                    ")")),
                            React.createElement("p", null, p.Description)),
                        React.createElement("div", { className: "col-12 col-lg-9" },
                            React.createElement(Participants, { participants: p.Members, introMessage: "", emptyMessage: "There is no one participating at this project.", showRole: true })))));
            })));
    };
    return TeamsBreakdown;
}(React.PureComponent));
export default TeamsBreakdown;
