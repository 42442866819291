var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import Skeleton from "./Skeleton";
import { Cards } from ".";
var Participants = /** @class */ (function (_super) {
    __extends(Participants, _super);
    function Participants() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.className = function (em) {
            if (em === void 0) { em = ""; }
            return _this.props.className ? "".concat(_this.props.className).concat(em) : "";
        };
        return _this;
    }
    Participants.prototype.skel = function () {
        return (React.createElement("div", null,
            React.createElement("h4", { className: "h4" },
                React.createElement(Skeleton, { width: 20 })),
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement(Skeleton, { width: 30 })),
                React.createElement("li", null,
                    React.createElement(Skeleton, { width: 30 })))));
    };
    Participants.prototype.render = function () {
        var _a = this.props, participants = _a.participants, introMessage = _a.introMessage, emptyMessage = _a.emptyMessage, showRole = _a.showRole;
        return (React.createElement("div", null,
            React.createElement("p", { className: this.className("__introMsg") }, introMessage),
            React.createElement(Cards, { className: "people", emptyMessage: emptyMessage, items: participants
                    ? participants.map(function (p) {
                        return {
                            id: p.Name,
                            name: p.Name,
                            link: null,
                            description: null,
                            icon: p.AvatarUrl ? p.AvatarUrl : null,
                            tag: showRole ? p.Role : null
                        };
                    })
                    : null })));
    };
    return Participants;
}(React.PureComponent));
export default Participants;
