var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { ProjectStatus } from "../models";
import { AxiosFlexible } from "../utils/Axios";
import ApiRoutes from "../utils/ApiRoutes";
import RouteBuilder from "../utils/RouteBuilder";
import { ProjectCardsNew } from ".";
var UserProjectListNew = /** @class */ (function (_super) {
    __extends(UserProjectListNew, _super);
    function UserProjectListNew() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isMounted = false;
        _this.state = {
            projects: null,
            hasError: false
        };
        return _this;
    }
    UserProjectListNew.prototype.componentDidMount = function () {
        this._isMounted = true;
        this.fetchProjects();
    };
    UserProjectListNew.prototype.componentWillUnmount = function () {
        this._isMounted = false;
    };
    UserProjectListNew.prototype.setState = function (state) {
        this._isMounted && _super.prototype.setState.call(this, state);
    };
    UserProjectListNew.prototype.fetchProjects = function () {
        var _this = this;
        AxiosFlexible()
            .get(ApiRoutes.UserProjects)
            .then(function (res) {
            if (res.status) {
                //this.setState({ projects: res.data.Projects });
                _this.setState({ projects: require("../data/mock/Projects.ts") }); //TODO: remove this line
            }
            else {
                _this.setState({ hasError: true });
            }
        })
            .catch(function (err) {
            _this.setState({ hasError: true });
        });
    };
    UserProjectListNew.prototype.render = function () {
        var _a = this.state, projects = _a.projects, hasError = _a.hasError;
        if (hasError) {
            return (React.createElement("p", { className: "small" },
                React.createElement("em", null,
                    "You have no upcoming projects.",
                    React.createElement("sup", null, "*"))));
        }
        return (React.createElement(ProjectCardsNew, { emptyMessage: "You have no upcoming projects.", items: projects
                ? projects.map(function (project) {
                    return {
                        id: project.Id,
                        name: project.Name,
                        icon: project.LogoUrl || require("../../images/icon-project.png"),
                        description: project.Description || "",
                        link: RouteBuilder("", project.OrganisationId, project.EventId, project.Id),
                        tag: project.Status || ProjectStatus.Pending
                    };
                })
                : null }));
    };
    return UserProjectListNew;
}(React.PureComponent));
export default UserProjectListNew;
