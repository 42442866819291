var shuffle = function (source) {
    var _a;
    for (var i = source.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        _a = [source[j], source[i]], source[i] = _a[0], source[j] = _a[1];
    }
    return source;
};
var pick = function (source) {
    var amount = Math.floor(Math.random() * (source.length - 1 + 1)) + 1;
    var sliced = shuffle(source.slice(0, amount));
    return sliced;
};
var members = function () {
    var opts = [
        "Vallie Lay",
        "Rayna Heckard",
        "Tambra Summerville",
        "Jamison Dudley",
        "Ethel Eddington",
        "Carissa Varnum",
        "Leena Learned",
        "Royal Nau",
        "Alex Daily",
        "Tresa Lingerfelt",
        "Carlene Luebke",
        "Refugio Paxton",
        "Meg Loyd",
        "Usha Calero",
        "Savanna Goodlow",
        "Jasper Thieme",
        "Tamela Cuevas",
        "Maddie Shinault",
        "Elisha Nowland",
        "Dillon Bothwell"
    ];
    return pick(opts);
};
var organisations = function () {
    var opts = [
        "carsales",
        "tyresales",
        "boatsales",
        "trucksales",
        "caravancampingsales",
        "constructionsales",
        "farmmachinerysales"
    ];
    return pick(opts);
};
var projects = function () {
    var opts = [
        "A park bench that your doctor prescribes for you.",
        "A firecracker that encourages sharing.",
        "A calendar that makes you conscious of its environmental impact.",
        "A map that boosts your self-confidence.",
        "A storybook that makes you money.",
        "A mattress that keeps you safe.",
        "A way to transfer money for a king or queen.",
        "A microphone to use on a road trip.",
        "A towel that puts you to sleep.",
        "A laptop for a social media addict."
    ];
    return pick(opts);
};
var events = function () {
    var opts = [
        "It’s not a bug, it's a feature",
        "HackOverflow",
        "404 Not Found",
        "Bits Magnet",
        "Kick the BitBucket",
        "Ctrl Alt Del bitch",
        "Jenkins Spice Latte",
        "Conway’s Alternative Hacks",
        "Baby Got Hack"
    ];
    return pick(opts);
};
var RandomContent = {
    projects: projects,
    organisations: organisations,
    events: events,
    members: members
};
export default RandomContent;
