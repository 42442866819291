var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import Skeleton from "./Skeleton";
import { getOrganisationById, getEventByIdAndOrgId } from "../utils/CommonRequests";
import RouteBuilder from "../utils/RouteBuilder";
import { Link } from "react-router-dom";
var PageHeader = /** @class */ (function (_super) {
    __extends(PageHeader, _super);
    function PageHeader() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            organisation: null,
            event: null
        };
        return _this;
    }
    PageHeader.prototype.componentDidMount = function () {
        var _this = this;
        var org = this.props.orgId
            ? getOrganisationById(this.props.orgId)
            : Promise.resolve(null);
        var event = this.props.eventId
            ? getEventByIdAndOrgId(this.props.orgId, this.props.eventId)
            : Promise.resolve(null);
        Promise.all([org, event])
            .then(function (res) {
            _this.setState({
                organisation: res[0],
                event: res[1]
            });
        })
            .catch(function () {
            // FailNotification("Error loading project information.");
        });
    };
    PageHeader.prototype.breadcrumbSep = function (key) {
        return (React.createElement("span", { className: "pageHeader__sep", key: key }, "/"));
    };
    PageHeader.prototype.breadcrumb = function () {
        var _a = this.state, organisation = _a.organisation, event = _a.event;
        var _b = this.props, orgId = _b.orgId, eventId = _b.eventId;
        var isLoading = false;
        if (orgId && !organisation) {
            isLoading = true;
        }
        if (eventId && !event) {
            isLoading = true;
        }
        if (isLoading) {
            return (React.createElement("div", { className: "pageHeader__breadcrumb" },
                React.createElement(Skeleton, { key: "skel", width: 30, class: "inline" })));
        }
        var parts = [
            React.createElement(Link, { key: "dashboard", to: "/dashboard/" }, "Dashboard")
        ];
        if (orgId) {
            parts.push(this.breadcrumbSep(parts.length));
            parts.push(React.createElement(Link, { key: parts.length, to: RouteBuilder("", organisation.Id) }, organisation.Name));
        }
        if (eventId && orgId) {
            parts.push(this.breadcrumbSep(parts.length));
            parts.push(React.createElement(Link, { key: parts.length, to: RouteBuilder("", organisation.Id, event.Id) }, event.Name));
        }
        parts.push(this.breadcrumbSep(parts.length));
        // parts.push(<strong key={parts.length}>{this.props.title}</strong>);
        return React.createElement("div", { className: "pageHeader__breadcrumb" }, parts);
    };
    PageHeader.prototype.render = function () {
        var props = this.props;
        var color = props.color || "#007cc2";
        return (React.createElement("header", { className: "pageHeader", style: { backgroundColor: color } },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "pageHeader__content" },
                    React.createElement("div", { className: "pageHeader__main" },
                        props.logo && React.createElement("img", { className: "pageHeader__logo", src: props.logo }),
                        React.createElement("div", null,
                            React.createElement("div", { className: "pageHeader__title" },
                                React.createElement("h1", { className: "h1" }, props.title || React.createElement(Skeleton, null))),
                            this.breadcrumb())),
                    props.children ? React.createElement("div", { className: "pageHeader__secondary" }, props.children) : null))));
    };
    return PageHeader;
}(React.PureComponent));
export default PageHeader;
