var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { PageHeader, MarkdownEditor } from "../components";
import RouteBuilder from "../utils/RouteBuilder";
import ApiRoutes from "../utils/ApiRoutes";
import { FailNotification, SuccessNotification } from "../utils/NotificationEvent";
import { ShowMediaLibrary } from "../utils/MediaLibraryEvent";
import { AxiosStrict, AxiosFlexible } from "../utils/Axios";
import { MediaType } from "../models/Media";
var EventEditor = /** @class */ (function (_super) {
    __extends(EventEditor, _super);
    function EventEditor() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            eName: "",
            eLogo: "",
            eContent: "",
            eDescription: ""
        };
        _this.handleSubmit = function (e) {
            e.preventDefault();
            if (!_this.isValid)
                return;
            var payload = {
                name: _this.state.eName,
                logo: _this.state.eLogo,
                content: _this.state.eContent,
                description: _this.state.eDescription
            };
            AxiosFlexible()
                .put(RouteBuilder(ApiRoutes.Events, _this.params.orgId, _this.params.eventId), payload)
                .then(function (res) {
                if (res.status === 200) {
                    var data = res.data.Event;
                    SuccessNotification("Event has been updated.");
                    // setTimeout(() => {
                    _this.props.history.push("".concat(RouteBuilder("", data.OrganisationId, data.Id)));
                }
            })
                .catch(function (err) {
                FailNotification("Error updating event.");
            });
        };
        return _this;
    }
    Object.defineProperty(EventEditor.prototype, "params", {
        get: function () {
            return this.props.match.params;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventEditor.prototype, "isValid", {
        get: function () {
            var _a = this.state, eName = _a.eName, eContent = _a.eContent;
            var isValid = true;
            // if (!eName.trim().length || !eContent.trim().length) {
            if (!eContent.trim().length) {
                isValid = false;
            }
            return isValid;
        },
        enumerable: false,
        configurable: true
    });
    EventEditor.prototype.componentDidMount = function () {
        this.fetchOrgData();
    };
    EventEditor.prototype.fetchOrgData = function () {
        var _this = this;
        AxiosStrict()
            .get(RouteBuilder(ApiRoutes.Events, this.params.orgId, this.params.eventId))
            .then(function (res) {
            if (res.status === 200) {
                var details = res.data.Event;
                _this.setState({
                    eName: details.Name || "",
                    eLogo: details.LogoUrl || "",
                    eContent: details.Content || "",
                    eDescription: details.Description || ""
                });
            }
        });
    };
    EventEditor.prototype.render = function () {
        var _this = this;
        var _a = this.state, eName = _a.eName, eLogo = _a.eLogo, eContent = _a.eContent, eDescription = _a.eDescription;
        return (React.createElement("div", null,
            React.createElement(PageHeader, { title: "Edit event", orgId: this.params.orgId, eventId: this.params.eventId }),
            React.createElement("div", { className: "container" },
                React.createElement("form", { onSubmit: this.handleSubmit },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "form-group col-12 col-sm-6" },
                            React.createElement("label", { htmlFor: "eName" }, "Name:"),
                            React.createElement("input", { className: "form-control", type: "text", name: "eName", value: eName, onChange: function (e) {
                                    _this.setState({ eName: e.target.value });
                                } }),
                            React.createElement("small", { className: "form-text text-muted" }, "Event name.")),
                        React.createElement("div", { className: "form-group col-12 col-sm-6" },
                            React.createElement("label", { htmlFor: "eLogo" }, "Logo:"),
                            React.createElement("input", { className: "form-control", type: "text", name: "eLogo", value: eLogo, placeholder: "E.g. https://placehold.it/100x100", onChange: function (e) {
                                    _this.setState({ eLogo: e.target.value });
                                } }),
                            React.createElement("div", { className: "float-right" },
                                React.createElement("div", { className: "btn btn-info btn-sm mt-1", onClick: function (e) {
                                        e.preventDefault();
                                        ShowMediaLibrary(function (res) { return res && _this.setState({ eLogo: res.Link }); }, MediaType.Image, false);
                                    } }, "Upload Logo")),
                            React.createElement("small", { className: "form-text text-muted" }, "Insert a complete URL."))),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "form-group col-12" },
                            React.createElement("label", { htmlFor: "Description" }, "Description:"),
                            React.createElement("input", { className: "form-control", type: "text", name: "Description", value: eDescription, onChange: function (e) {
                                    _this.setState({ eDescription: e.target.value });
                                } }),
                            React.createElement("small", { className: "form-text text-muted" },
                                "Event description. ",
                                React.createElement("em", null, "Short and sweet.")))),
                    React.createElement("h4", { className: "h4 mt-5" }, "Event Overview"),
                    React.createElement(MarkdownEditor, { content: eContent, onContentUpdate: function (eContent) { return _this.setState({ eContent: eContent }); } }),
                    React.createElement("div", { className: "btn-group mt-5 mb-5 text-right" },
                        React.createElement("button", { className: "btn btn-success", type: "submit", disabled: !this.isValid }, "Save"),
                        React.createElement("a", { href: "#", onClick: function (e) {
                                e.preventDefault();
                                _this.props.history.push("".concat(RouteBuilder("", _this.params.eId)));
                            }, className: "btn btn-text" }, "Back"))))));
    };
    return EventEditor;
}(React.PureComponent));
export default EventEditor;
