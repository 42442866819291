var RouteBuilder = function (base, orgId, eventId, projectId) {
    if (base === void 0) { base = ""; }
    if (orgId === void 0) { orgId = ""; }
    if (eventId === void 0) { eventId = ""; }
    if (projectId === void 0) { projectId = ""; }
    var route = "";
    if (projectId) {
        route = "".concat(projectId);
    }
    if (eventId) {
        route = "".concat(eventId, "/").concat(route);
    }
    route = "".concat(base, "/").concat(orgId, "/").concat(route);
    //Ensure trailing slash
    if (route.slice(-1) !== "/") {
        route = "".concat(route, "/");
    }
    return route;
};
export default RouteBuilder;
