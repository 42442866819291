import React from "react";
import { CardsSkeleton } from ".";
import { Link } from "react-router-dom";
var cardInner = function (card) {
    return (React.createElement("div", { className: "cards__innerWrap" },
        card.icon && React.createElement("div", { className: "cards__logo", style: { backgroundImage: "url(".concat(card.icon, ")") } }),
        React.createElement("span", { className: "cards__details" },
            card.name,
            card.description && React.createElement("p", { className: "cards__desc" }, card.description)),
        card.tag && React.createElement("div", { className: "cards__tag" }, card.tag)));
};
var Cards = function (props) {
    var items = props.items, emptyMessage = props.emptyMessage, className = props.className;
    if (items === null) {
        return React.createElement(CardsSkeleton, { className: className });
    }
    if (items && !items.length)
        return (React.createElement("p", { className: "small" },
            React.createElement("em", null, emptyMessage)));
    return (React.createElement("div", { className: "cards ".concat(className ? "cards--".concat(className) : "", " ") }, items.map(function (card) {
        var itemClassName = ["cards__item"];
        card.className && itemClassName.push(card.className);
        card.tag && itemClassName.push(card.tag);
        return (React.createElement("div", { className: itemClassName.join(" "), key: card.id }, card.link ? (React.createElement(Link, { className: "cards__inner", to: card.link }, cardInner(card))) : (React.createElement("div", { className: "cards__inner" }, cardInner(card)))));
    })));
};
export default Cards;
