var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import marked from "marked";
var MarkdownRender = /** @class */ (function (_super) {
    __extends(MarkdownRender, _super);
    function MarkdownRender() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MarkdownRender.prototype.getMarkdownText = function () {
        var rawMarkup = marked(this.props.raw, { sanitize: true });
        return { __html: rawMarkup || "No content." };
    };
    MarkdownRender.prototype.handleClick = function (e) {
        var tagName = "A";
        var videoHash = "#csHackVideoModal";
        var el = e.target;
        while (el && el !== e.currentTarget && el.tagName !== tagName) {
            el = el.parentNode;
        }
        if (el && el.tagName === tagName) {
            var href = el.href;
            if (href.substring(href.length - videoHash.length, href.length) === videoHash) {
                e.preventDefault();
                var evt = new CustomEvent("PlayVideo", {
                    detail: {
                        source: href
                    }
                });
                document.body.dispatchEvent(evt);
            }
        }
    };
    MarkdownRender.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: "md", onClick: function (e) { return _this.handleClick(e); }, dangerouslySetInnerHTML: this.getMarkdownText() });
    };
    return MarkdownRender;
}(React.PureComponent));
export default MarkdownRender;
