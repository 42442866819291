var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
var FAQListNew = /** @class */ (function (_super) {
    __extends(FAQListNew, _super);
    function FAQListNew() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FAQListNew.prototype.render = function () {
        var faqs = this.props.faqs;
        return (React.createElement("div", { className: "faqListNew" },
            React.createElement("p", { className: "faqListNew__heading" }, "Frequently Asked Questions"),
            faqs.map(function (faq, index) { return (React.createElement("div", { className: "faqListNew__accordion" },
                React.createElement(Accordion, null,
                    React.createElement(AccordionSummary, { expandIcon: React.createElement("img", { src: require("../../images/icon-down-arrow.svg").default }), "aria-controls": "panel".concat(index, "a-content"), id: "panel".concat(index, "a-header") },
                        React.createElement("span", { className: "faqListNew__question" }, faq.question)),
                    React.createElement(AccordionDetails, null,
                        React.createElement("span", { className: "faqListNew__answer" }, faq.answer))))); })));
    };
    return FAQListNew;
}(React.PureComponent));
export default FAQListNew;
