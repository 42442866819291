import { MediaType } from "../models/Media";
export var ShowMediaLibrary = function (callbackOnClose, filter, canChangeFilter) {
    if (callbackOnClose === void 0) { callbackOnClose = null; }
    if (filter === void 0) { filter = MediaType.Image; }
    if (canChangeFilter === void 0) { canChangeFilter = true; }
    var evt = new CustomEvent("MediaLibraryShow", {
        detail: { callbackOnClose: callbackOnClose, filter: filter, canChangeFilter: canChangeFilter }
    });
    document.body.dispatchEvent(evt);
};
export var HideMediaLibrary = function () {
    var evt = new CustomEvent("MediaLibraryHide", {
        detail: {}
    });
    document.body.dispatchEvent(evt);
};
