var participants = require("../mock/Participants.ts");
module.exports = [
    {
        Id: "tldr-otto-live-prototype",
        OrganisationId: "carsales-australia",
        EventId: "voyager-2025",
        Name: "TLDR - Otto (Live prototype!) (AU)",
        JoinOption: "InviteOnly",
        LogoUrl: "https://hackgorilla.pxcrush.net/ugc/cepvrezw7dbggdds4f1p38oao.jpg",
        Content: "test content",
        Description: "Introducing Otto! Carsales' new AI chatbot, telling you everything you auto know in your buying and selling journey.",
        Participants: participants,
        Status: "Active",
    },
    {
        Id: "exsellence-full",
        OrganisationId: "carsales-australia",
        EventId: "hacktoberfest",
        Name: "exSELLence (FULL)",
        JoinOption: "InviteOnly",
        LogoUrl: "https://hackgorilla.pxcrush.net/ugc/78kaovvcwxttpgiirw729lqhe.png",
        Content: "test content",
        Description: "Enhance the buying and selling experience by improving the ways of communication between the buyers and sellers.",
        Participants: participants,
        Status: "Inactive",
    },
    {
        Id: "auto-tagging-machine",
        OrganisationId: "carsales-australia",
        EventId: "mechathon",
        Name: "Auto Tagging Machine",
        JoinOption: "InviteOnly",
        LogoUrl: "https://hackgorilla.pxcrush.net/ugc/4hx1903vuuywkx6wzot8mjeau.png",
        Content: "test content",
        Description: "The Ultimate Spam SMS Detector",
        Participants: participants,
        Status: "Inactive",
    },
    {
        Id: "tldr-otto-live-prototype",
        OrganisationId: "carsales-australia",
        EventId: "voyager-2025",
        Name: "TLDR - Otto (Live prototype!) (AU)",
        JoinOption: "InviteOnly",
        LogoUrl: "https://hackgorilla.pxcrush.net/ugc/cepvrezw7dbggdds4f1p38oao.jpg",
        Content: "test content",
        Description: "Introducing Otto! Carsales' new AI chatbot, telling you everything you auto know in your buying and selling journey.",
        Participants: participants,
        Status: "Active",
    },
    {
        Id: "exsellence-full",
        OrganisationId: "carsales-australia",
        EventId: "hacktoberfest",
        Name: "exSELLence (FULL)",
        JoinOption: "InviteOnly",
        LogoUrl: "https://hackgorilla.pxcrush.net/ugc/78kaovvcwxttpgiirw729lqhe.png",
        Content: "test content",
        Description: "Enhance the buying and selling experience by improving the ways of communication between the buyers and sellers.",
        Participants: participants,
        Status: "Inactive",
    },
    {
        Id: "auto-tagging-machine",
        OrganisationId: "carsales-australia",
        EventId: "mechathon",
        Name: "Auto Tagging Machine",
        JoinOption: "InviteOnly",
        LogoUrl: "https://hackgorilla.pxcrush.net/ugc/4hx1903vuuywkx6wzot8mjeau.png",
        Content: "test content",
        Description: "The Ultimate Spam SMS Detector",
        Participants: participants,
        Status: "Inactive",
    },
];
