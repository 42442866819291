var _isFullyQualifiedPath = function (path) {
    return /^https?:\/\//.test(path);
};
var _getProtocol = function (path) {
    return /^(https?):\/\//i.exec(path)[1].toLowerCase();
};
var _getDomain = function (path) {
    return /^https?:\/\/([^\/]+)/i.exec(path)[1];
};
var _getFullyQualifiedPath = function (path) {
    if (_isFullyQualifiedPath(path)) {
        return path;
    }
    var currLocation = location.href;
    var protocol = _getProtocol(currLocation);
    if (path.startsWith("//")) {
        return "".concat(protocol, ":").concat(path);
    }
    var domain = _getDomain(currLocation);
    if (!path) {
        return "".concat(protocol, "://").concat(domain);
    }
    if (path.startsWith("/")) {
        return "".concat(protocol, "://").concat(domain).concat(path);
    }
    var cwd = _getDirectory(currLocation);
    while (path.startsWith(".")) {
        if (path.startsWith("./")) {
            path = path.substr(2);
        }
        if (path.startsWith("../")) {
            path = path.substr(3);
            cwd.pop();
        }
        if (path === "..") {
            path = "";
            cwd.pop();
        }
    }
    if (path.startsWith("/")) {
        path = path.substr(1);
    }
    return "".concat(protocol, "://").concat(domain, "/").concat(cwd.join("/")) + (cwd.length === 0 ? "" : "/") + path;
};
var _getDirectory = function (path) {
    var rel = /^https?:\/\/[^/]+(.*?)(?:$|\?)/i.exec(path)[1];
    if (rel.length === 0) {
        return [];
    }
    var tree = rel.split("/");
    if (tree[tree.length - 1].indexOf(".") >= 0) {
        tree.pop();
    }
    return tree.filter(function (x) { return x && x.length > 0; });
};
var getAbsolutePath = function (path) {
    var newLocation = _getFullyQualifiedPath(path);
    var currLocation = location.href;
    if (_getProtocol(newLocation) !== _getProtocol(currLocation)) {
        return newLocation;
    }
    if (_getDomain(newLocation) !== _getDomain(currLocation)) {
        return /^https?:(.+)/i.exec(newLocation)[1];
    }
    var abs = /^https?:\/\/[^\/]+(.+)/i.exec(newLocation)[1];
    return abs && abs.length > 0 ? abs : "/";
};
export default getAbsolutePath;
