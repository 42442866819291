module.exports = [
    {
        Name: "Jane",
        Role: "Member",
    },
    {
        Name: "Tome Holland",
        Role: "Member",
    },
    {
        Name: "Howard Wolowitz",
        Role: "Member",
    },
    {
        Name: "Amy",
        Role: "Member",
    },
    {
        Name: "Leonard Hosfstater",
        Role: "Member",
    },
    {
        Name: "Sheldon Cooper",
        Role: "Owner",
    },
];
