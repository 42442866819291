var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import uuidv1 from "uuid";
var randy = function (min, max) { return Math.floor(Math.random() * (max - min + 1)) + min; };
var IMustDance = /** @class */ (function (_super) {
    __extends(IMustDance, _super);
    function IMustDance() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { dancers: [] };
        _this.base = 180;
        _this.maxX = window.innerWidth;
        _this.maxY = window.innerHeight;
        _this.maxDancers = Math.round((_this.maxX / _this.base) * (_this.maxY / _this.base));
        _this.isDancing = true;
        _this.handleKeyDown = function (e) {
            if (e.keyCode === 27)
                _this.close();
        };
        _this.close = function () {
            document.body.removeEventListener("keydown", _this.handleKeyDown, true);
            _this.props.close();
            _this.isDancing = false;
        };
        _this.removeDancers = function () {
            if (!_this.isDancing)
                return;
            var dancers = _this.state.dancers.slice(0);
            dancers.shift();
            if (_this.state.dancers.length < 1) {
                return _this.addDancers();
            }
            _this.setState({ dancers: dancers }, function () {
                setTimeout(_this.removeDancers, 100);
            });
        };
        _this.addDancers = function (amountOfDancers) {
            if (amountOfDancers === void 0) { amountOfDancers = 1; }
            if (!_this.isDancing)
                return;
            if (_this.state.dancers.length === _this.maxDancers) {
                return _this.removeDancers();
            }
            var dancers = _this.state.dancers.slice(0);
            for (var i = 0; i < amountOfDancers; i++) {
                var scale = randy(30, 100) / 100;
                var x = randy(0, _this.maxX - _this.base * scale);
                var y = randy(0, _this.maxY - _this.base * scale);
                dancers.push({
                    id: uuidv1(),
                    scale: scale,
                    x: x,
                    y: y
                });
            }
            _this.setState({ dancers: dancers }, function () {
                setTimeout(_this.addDancers, 750);
            });
        };
        return _this;
    }
    IMustDance.prototype.componentDidMount = function () {
        document.body.addEventListener("keydown", this.handleKeyDown);
        this.addDancers();
    };
    IMustDance.prototype.render = function () {
        return (React.createElement("div", { className: "iMustDance", onClick: this.close },
            this.state.dancers.map(function (d, i) {
                return (React.createElement("div", { key: d.id, className: "iMustDance__dancerWrap", style: { top: d.y, left: d.x, transform: "scale(".concat(d.scale, ")") } },
                    React.createElement("img", { className: "iMustDance__dancer", style: { animationDelay: "-".concat(d.scale, "s") }, src: require("../../images/dancer.png"), width: "180", height: "180" })));
            }),
            React.createElement("h6", { className: "h6" }, "Click anywhere to go back.")));
    };
    return IMustDance;
}(React.PureComponent));
export default IMustDance;
