var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { PageHeader, MarkdownEditor } from "../components";
import RouteBuilder from "../utils/RouteBuilder";
import ApiRoutes from "../utils/ApiRoutes";
import { FailNotification, SuccessNotification } from "../utils/NotificationEvent";
import { ShowMediaLibrary } from "../utils/MediaLibraryEvent";
import { AxiosStrict, AxiosFlexible } from "../utils/Axios";
import { MediaType } from "../models/Media";
var OrganisationEditor = /** @class */ (function (_super) {
    __extends(OrganisationEditor, _super);
    function OrganisationEditor() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            orgName: "",
            orgLogo: "",
            orgContent: "",
            orgDescription: ""
        };
        _this.handleSubmit = function (e) {
            e.preventDefault();
            if (!_this.isValid)
                return;
            var payload = {
                name: _this.state.orgName,
                logo: _this.state.orgLogo,
                content: _this.state.orgContent,
                description: _this.state.orgDescription
            };
            AxiosFlexible()
                .put(RouteBuilder(ApiRoutes.Organisation, _this.params.orgId), payload)
                .then(function (res) {
                if (res.status === 200) {
                    var data = res.data.Organisation;
                    SuccessNotification("Organisation has been updated.");
                    _this.props.history.push("".concat(RouteBuilder("", data.Id)));
                }
            })
                .catch(function (err) {
                FailNotification("Error updating organisation.");
            });
        };
        return _this;
    }
    Object.defineProperty(OrganisationEditor.prototype, "params", {
        get: function () {
            return this.props.match.params;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrganisationEditor.prototype, "isValid", {
        get: function () {
            var _a = this.state, orgName = _a.orgName, orgContent = _a.orgContent;
            var isValid = true;
            // if (!orgName.trim().length || !orgContent.trim().length) {
            if (!orgContent.trim().length) {
                isValid = false;
            }
            return isValid;
        },
        enumerable: false,
        configurable: true
    });
    OrganisationEditor.prototype.componentDidMount = function () {
        this.fetchOrgData();
    };
    OrganisationEditor.prototype.fetchOrgData = function () {
        var _this = this;
        AxiosStrict()
            .get("".concat(ApiRoutes.Organisation, "/").concat(this.params.orgId))
            .then(function (res) {
            if (res.status === 200) {
                var details = res.data.Organisation;
                _this.setState({
                    orgName: details.Name || "",
                    orgLogo: details.LogoUrl || "",
                    orgContent: details.Content || ""
                });
            }
        });
    };
    OrganisationEditor.prototype.render = function () {
        var _this = this;
        var _a = this.state, orgName = _a.orgName, orgLogo = _a.orgLogo, orgContent = _a.orgContent;
        return (React.createElement("div", null,
            React.createElement(PageHeader, { title: "Edit organisation", orgId: this.params.orgId, eventId: this.params.eventId }),
            React.createElement("div", { className: "container" },
                React.createElement("form", { onSubmit: this.handleSubmit },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "form-group col-12 col-sm-6" },
                            React.createElement("label", { htmlFor: "orgName" }, "Name:"),
                            React.createElement("input", { className: "form-control", type: "text", name: "orgName", value: orgName, onChange: function (e) {
                                    _this.setState({ orgName: e.target.value });
                                } }),
                            React.createElement("small", { className: "form-text text-muted" }, "Your organisation name.")),
                        React.createElement("div", { className: "form-group col-12 col-sm-6" },
                            React.createElement("label", { htmlFor: "orgLogo" }, "Logo:"),
                            React.createElement("input", { className: "form-control", type: "text", name: "orgLogo", value: orgLogo, placeholder: "E.g. https://placehold.it/100x100", onChange: function (e) {
                                    _this.setState({ orgLogo: e.target.value });
                                } }),
                            React.createElement("div", { className: "float-right" },
                                React.createElement("div", { className: "btn btn-info btn-sm mt-1", onClick: function (e) {
                                        e.preventDefault();
                                        ShowMediaLibrary(function (res) { return res && _this.setState({ orgLogo: res.Link }); }, MediaType.Image, false);
                                    } }, "Upload Logo")),
                            React.createElement("small", { className: "form-text text-muted" }, "Insert a complete URL."))),
                    React.createElement("h4", { className: "h4 mt-5" }, "Organisation Overview"),
                    React.createElement(MarkdownEditor, { content: orgContent, onContentUpdate: function (orgContent) { return _this.setState({ orgContent: orgContent }); } }),
                    React.createElement("div", { className: "btn-group mt-5 mb-5 text-right" },
                        React.createElement("button", { className: "btn btn-success", type: "submit", disabled: !this.isValid }, "Save"),
                        React.createElement("a", { href: "#", onClick: function (e) {
                                e.preventDefault();
                                _this.props.history.push("".concat(RouteBuilder("", _this.params.orgId)));
                            }, className: "btn btn-text" }, "Back"))))));
    };
    return OrganisationEditor;
}(React.PureComponent));
export default OrganisationEditor;
