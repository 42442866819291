var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import Skeleton from "./Skeleton";
import { AxiosFlexible } from "../utils/Axios";
import ApiRoutes from "../utils/ApiRoutes";
import { UserInvitationNew } from ".";
var UserInvitationsListNew = /** @class */ (function (_super) {
    __extends(UserInvitationsListNew, _super);
    function UserInvitationsListNew() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isMounted = false;
        _this.state = {
            invites: null,
            isError: false
        };
        return _this;
    }
    UserInvitationsListNew.prototype.componentDidMount = function () {
        this._isMounted = true;
        this.fetchInvitations();
    };
    UserInvitationsListNew.prototype.componentWillUnmount = function () {
        this._isMounted = false;
    };
    UserInvitationsListNew.prototype.setState = function (state) {
        this._isMounted && _super.prototype.setState.call(this, state);
    };
    UserInvitationsListNew.prototype.fetchInvitations = function () {
        var _this = this;
        AxiosFlexible()
            .get(ApiRoutes.UserInvitations)
            .then(function (res) {
            if (res.status) {
                _this.setState({ invites: res.data.Invitations });
            }
            else {
                _this.setState({ isError: true });
            }
        })
            .catch(function (err) {
            _this.setState({ isError: true });
        });
    };
    UserInvitationsListNew.prototype.render = function () {
        var _this = this;
        var _a = this.state, invites = _a.invites, isError = _a.isError;
        if (invites === null && !isError)
            return (React.createElement("ul", { className: "invites" },
                React.createElement("li", { className: "invites__invite" },
                    React.createElement(Skeleton, { width: 75 }))));
        if (isError || invites.length < 1) {
            return (React.createElement("p", { className: "small" },
                React.createElement("em", null,
                    "You have no pending invitations.",
                    isError && "*")));
        }
        return (React.createElement("ul", { className: "invites" }, invites.map(function (invite) { return (React.createElement(UserInvitationNew, { invitation: invite, key: invite.Id, onError: function () { return _this.setState({ isError: true }); } })); })));
    };
    return UserInvitationsListNew;
}(React.PureComponent));
export default UserInvitationsListNew;
