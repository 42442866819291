import React from "react";
import Gorilla from "../utils/Gorilla";
var Credits = function (props) {
    return (React.createElement("div", { className: "credits" },
        React.createElement("pre", null,
            React.createElement("code", null, Gorilla)),
        React.createElement("h3", { className: "h3" }, "The HackGorilla Team"),
        React.createElement("p", null,
            "Jason Blackman",
            React.createElement("br", null),
            "Anthony Paes",
            React.createElement("br", null),
            "Michael Raffaele",
            React.createElement("br", null),
            "Tuan Hong",
            React.createElement("br", null),
            "Mo Abbas")));
};
export default Credits;
