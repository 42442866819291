var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { InvitationTypes } from "../models";
import { FailNotification, SuccessNotification } from "../utils/NotificationEvent";
import { getInvitations, acceptInvitation, rejectInvitation } from "../utils/CommonRequests";
var InviteBar = /** @class */ (function (_super) {
    __extends(InviteBar, _super);
    function InviteBar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            invite: null
        };
        _this.filterRelevantInvite = function (invitations) {
            var _a = _this.props, orgId = _a.orgId, eventId = _a.eventId, projectId = _a.projectId;
            var filtered = invitations.filter(function (invitation) {
                if (invitation.Type !== _this.type)
                    return false;
                switch (invitation.Type) {
                    case InvitationTypes.Project:
                        return invitation.Project === projectId;
                        break;
                    case InvitationTypes.Event:
                        return invitation.Event === eventId;
                        break;
                    case InvitationTypes.Organisation:
                        return invitation.Organisation === orgId;
                        break;
                }
                return true;
            });
            return filtered.length > 0 ? filtered[0] : null;
        };
        _this.accept = function () {
            acceptInvitation(_this.state.invite.Id)
                .then(function (res) {
                SuccessNotification("Invitation accepted. Welcome to the ".concat(_this.type, "."));
                _this.setState({ invite: null });
            })
                .catch(function (res) { return FailNotification("Error accepting invite."); });
        };
        _this.reject = function () {
            rejectInvitation(_this.state.invite.Id)
                .then(function (res) {
                SuccessNotification("Invitation rejected. What a shame.");
                _this.setState({ invite: null });
            })
                .catch(function (res) { return FailNotification("Error accepting invite."); });
        };
        return _this;
    }
    Object.defineProperty(InviteBar.prototype, "type", {
        get: function () {
            var _a = this.props, projectId = _a.projectId, eventId = _a.eventId;
            if (projectId) {
                return InvitationTypes.Project;
            }
            if (eventId) {
                return InvitationTypes.Event;
            }
            return InvitationTypes.Organisation;
        },
        enumerable: false,
        configurable: true
    });
    InviteBar.prototype.componentDidMount = function () {
        var _this = this;
        getInvitations()
            .then(function (res) {
            var invite = _this.filterRelevantInvite(res);
            _this.setState({ invite: invite });
        })
            .catch(function (err) {
            // FailNotification("Error loading invitations.");
        });
    };
    InviteBar.prototype.render = function () {
        var invite = this.state.invite;
        var _a = this.props;
        if (invite === null)
            return null;
        return (React.createElement("div", { className: "inviteBar" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "inviteBar__content" },
                    React.createElement("span", null,
                        "You've been invited to join this ",
                        InvitationTypes[this.type].toLowerCase(),
                        "."),
                    React.createElement("button", { className: "btn btn-sm btn-success ml-3", onClick: this.accept }, "Count me in!"),
                    React.createElement("button", { className: "btn btn-sm btn-danger ml-1", onClick: this.reject }, "No thanks")))));
    };
    return InviteBar;
}(React.PureComponent));
export default InviteBar;
