var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { Cards } from ".";
var EventList = /** @class */ (function (_super) {
    __extends(EventList, _super);
    function EventList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EventList.prototype.render = function () {
        var events = this.props.events;
        var upcoming = events ? events.filter(function (ev) { return ev.Status == "Upcoming"; }) : [];
        var past = events ? events.filter(function (ev) { return ev.Status == "Past"; }) : [];
        return (React.createElement("div", null,
            (!events || events.length == 0) &&
                React.createElement("div", null,
                    React.createElement("h4", null, "Events"),
                    React.createElement("p", null, "This organisation has no events.")),
            upcoming.length > 0 && (React.createElement("div", null,
                React.createElement("h4", null, "Upcoming / Current Events"),
                React.createElement(Cards, { emptyMessage: "No upcoming/current events found.", items: upcoming.map(function (event) {
                        return {
                            id: event.Id,
                            name: event.Name,
                            link: "".concat(document.location.pathname).concat(event.Id),
                            icon: event.LogoUrl || require("../../images/icon-event.png"),
                            description: event.Description
                        };
                    }) }))),
            past.length > 0 && (React.createElement("div", null,
                React.createElement("h4", null, "Past Events"),
                React.createElement(Cards, { emptyMessage: "No past events found.", items: past.map(function (event) {
                        return {
                            id: event.Id,
                            name: event.Name,
                            link: "".concat(document.location.pathname).concat(event.Id),
                            icon: event.LogoUrl || require("../../images/icon-event.png"),
                            description: event.Description
                        };
                    }) })))));
    };
    return EventList;
}(React.PureComponent));
export default EventList;
