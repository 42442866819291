import { NotificationType } from "../models";
var defaultTimeout = 2500;
export var FailNotification = function (message, timeout) {
    if (timeout === void 0) { timeout = defaultTimeout; }
    var evt = new CustomEvent("notify", {
        detail: {
            message: message,
            type: NotificationType.Fail
        }
    });
    document.body.dispatchEvent(evt);
};
export var SuccessNotification = function (message, timeout) {
    if (timeout === void 0) { timeout = defaultTimeout; }
    var evt = new CustomEvent("notify", {
        detail: {
            message: message,
            type: NotificationType.Success
        }
    });
    document.body.dispatchEvent(evt);
};
export var InfoNotification = function (message, timeout) {
    if (timeout === void 0) { timeout = defaultTimeout; }
    var evt = new CustomEvent("notify", {
        detail: {
            message: message,
            type: NotificationType.Info
        }
    });
    document.body.dispatchEvent(evt);
};
