module.exports = [
    {
        Id: "tldr-otto-live-prototype",
        OrganisationId: "carsales-australia",
        EventId: "voyager-2025",
        Name: "TLDR - Otto (Live prototype!) (AU)",
        LogoUrl: "https://hackgorilla.pxcrush.net/ugc/cepvrezw7dbggdds4f1p38oao.jpg",
        Content: "test content",
        Description: "Introducing Otto! Carsales' new AI chatbot, telling you everything you auto know in your buying and selling journey.",
    },
    {
        Id: "tldr-otto-live-prototype",
        OrganisationId: "carsales-australia",
        EventId: "voyager-2025",
        Name: "TLDR - Otto (Live prototype!) (AU)",
        LogoUrl: "https://hackgorilla.pxcrush.net/ugc/cepvrezw7dbggdds4f1p38oao.jpg",
        Content: "test content",
        Description: "Introducing Otto! Carsales' new AI chatbot, telling you everything you auto know in your buying and selling journey.",
    },
    {
        Id: "tldr-otto-live-prototype",
        OrganisationId: "carsales-australia",
        EventId: "voyager-2025",
        Name: "TLDR - Otto (Live prototype!) (AU)",
        LogoUrl: "https://hackgorilla.pxcrush.net/ugc/cepvrezw7dbggdds4f1p38oao.jpg",
        Content: "test content",
        Description: "Introducing Otto! Carsales' new AI chatbot, telling you everything you auto know in your buying and selling journey.",
    },
    {
        Id: "tldr-otto-live-prototype",
        OrganisationId: "carsales-australia",
        EventId: "voyager-2025",
        Name: "TLDR - Otto (Live prototype!) (AU)",
        LogoUrl: "https://hackgorilla.pxcrush.net/ugc/cepvrezw7dbggdds4f1p38oao.jpg",
        Content: "test content",
        Description: "Introducing Otto! Carsales' new AI chatbot, telling you everything you auto know in your buying and selling journey.",
    },
    {
        Id: "tldr-otto-live-prototype",
        OrganisationId: "carsales-australia",
        EventId: "voyager-2025",
        Name: "TLDR - Otto (Live prototype!) (AU)",
        LogoUrl: "https://hackgorilla.pxcrush.net/ugc/cepvrezw7dbggdds4f1p38oao.jpg",
        Content: "test content",
        Description: "Introducing Otto! Carsales' new AI chatbot, telling you everything you auto know in your buying and selling journey.",
    },
];
