var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { PageHeader, MarkdownRender, MarkdownSkeleton, InviteBar, Participants, ControlledTabs, InviteForm, Skeleton, Loading } from "../components";
import { RoleType, ProjectJoinOptions } from "../models";
import ApiRoutes from "../utils/ApiRoutes";
import RouteBuilder from "../utils/RouteBuilder";
import { Link } from "react-router-dom";
import { FailNotification, SuccessNotification } from "../utils/NotificationEvent";
import { AxiosStrict, AxiosFlexible } from "../utils/Axios";
var Project = /** @class */ (function (_super) {
    __extends(Project, _super);
    function Project() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            details: null,
            userPermissions: null,
            qrCode: null
        };
        _this.downloadQR = function (e) {
            if (e)
                e.preventDefault();
            var blob = _this.state.qrCode;
            var filename = "QR_".concat(_this.state.details.Name, ".svg");
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, filename);
            }
            else {
                var link = document.createElement("a");
                if (link.download !== undefined) {
                    link.setAttribute("href", _this.blobUrl);
                    link.setAttribute("download", filename);
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        };
        return _this;
    }
    Object.defineProperty(Project.prototype, "params", {
        get: function () {
            return this.props.match.params;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "blobUrl", {
        get: function () {
            var urlCreator = window["URL"] || window["webkitURL"];
            return urlCreator.createObjectURL(this.state.qrCode);
        },
        enumerable: false,
        configurable: true
    });
    Project.prototype.componentDidMount = function () {
        document.title = "Project | Hackathon Portal";
        this.fetchProjectData();
    };
    Project.prototype.fetchPermissions = function () {
        var _this = this;
        AxiosStrict()
            .get("".concat(ApiRoutes.UserProjects, "/").concat(this.params.orgId, "/").concat(this.params.eventId, "/").concat(this.params.projectId))
            .then(function (res) {
            if (res.status === 200) {
                _this.setState({ userPermissions: res.data.Project });
            }
        });
    };
    Project.prototype.fetchProjectData = function () {
        var _this = this;
        AxiosStrict()
            .get(RouteBuilder(ApiRoutes.Projects, this.params.orgId, this.params.eventId, this.params.projectId))
            .then(function (res) {
            switch (res.status) {
                case 200:
                    document.title = "".concat(res.data.Project.Name, " | Hackathon Portal");
                    _this.setState({ details: res.data.Project });
                    _this.fetchPermissions();
                    _this.fetchQrCode();
                    break;
                default:
                    _this.props.history.push("/error/".concat(res.status, "/?from=").concat(window.location.href));
                    break;
            }
        });
    };
    Project.prototype.fetchQrCode = function () {
        var _this = this;
        var url = "".concat(RouteBuilder("".concat(document.location.protocol, "//").concat(document.location.host), this.params.orgId, this.params.eventId, this.params.projectId), "vote/");
        // const filename = `QR_${this.state.details.Name}.svg`;
        AxiosFlexible()
            .get("".concat(ApiRoutes.QRCode, "?url=").concat(btoa(url)))
            .then(function (res) {
            _this.setState({ qrCode: new Blob([res.data], { type: "image/svg+xml" }) });
            // var blob = new Blob([res.data], { type: "image/svg+xml" });
            // if (navigator.msSaveBlob) {
            //   navigator.msSaveBlob(blob, filename);
            // } else {
            //   var link = document.createElement("a");
            //   if (link.download !== undefined) {
            //     var url = URL.createObjectURL(blob);
            //     link.setAttribute("href", url);
            //     link.setAttribute("download", filename);
            //     link.style.visibility = "hidden";
            //     document.body.appendChild(link);
            //     link.click();
            //     document.body.removeChild(link);
            //   }
            // }
        })
            .catch(function (err) {
            FailNotification("QR Code generation failed.");
            // console.log(err, err.response);
        });
    };
    Project.prototype.leaveProject = function () {
        var _this = this;
        AxiosFlexible()
            .post("".concat(RouteBuilder(ApiRoutes.UserProjectLeave, this.params.orgId, this.params.eventId, this.params.projectId)), {})
            .then(function (res) {
            if (res.status === 200) {
                SuccessNotification("You've left the project.");
                _this.setState({ details: null, userPermissions: null });
                _this.fetchProjectData();
            }
            else {
                FailNotification("Unable to leave right now.");
            }
        })
            .catch(function (err) {
            FailNotification("Unable to leave right now.");
        });
    };
    Project.prototype.joinProject = function () {
        var _this = this;
        AxiosFlexible()
            .post("".concat(RouteBuilder(ApiRoutes.UserProjectJoin, this.params.orgId, this.params.eventId, this.params.projectId)), {})
            .then(function (res) {
            if (res.status === 200) {
                SuccessNotification("You've joined the project.");
                _this.setState({ details: null, userPermissions: null });
                _this.fetchProjectData();
            }
            else {
                FailNotification("Unable to join right now.");
            }
        })
            .catch(function (err) {
            FailNotification("Unable to join right now.");
        });
    };
    Project.prototype.permissions = function () {
        var _a = this.state, userPermissions = _a.userPermissions, details = _a.details;
        var permissions = {
            edit: false,
            invite: false,
            leave: false,
            join: false,
            vote: false,
            isOwner: false
        };
        if (userPermissions) {
            var Permissions_1 = userPermissions.Permissions, Roles = userPermissions.Roles;
            permissions.isOwner = Roles.Project === RoleType.Owner;
            permissions.edit = Permissions_1.includes("project_edit");
            permissions.invite = Permissions_1.includes("project_invite");
            permissions.leave =
                Roles.Project !== RoleType.Inactive && Roles.Project !== RoleType.Owner && Roles.Project !== null;
            permissions.join =
                Permissions_1.includes("project_join") &&
                    (Roles.Project === null || Roles.Project === RoleType.Inactive) &&
                    details.JoinOption === ProjectJoinOptions.Anybody;
            permissions.vote = Permissions_1.includes("project_vote") && userPermissions.Voteable;
        }
        return permissions;
    };
    Project.prototype.render = function () {
        var _this = this;
        var _a = this.state, details = _a.details, qrCode = _a.qrCode;
        var imageUrl = "";
        var perms = this.permissions();
        var tabs = [
            {
                label: "Overview",
                content: details ? React.createElement(MarkdownRender, { raw: details.Content }) : React.createElement(MarkdownSkeleton, null)
            },
            {
                label: "Members",
                content: (React.createElement("div", null,
                    (perms.leave || perms.isOwner) && (React.createElement("button", { className: "btn btn-success btn-sm abs-r", disabled: perms.isOwner, onClick: function () { return !perms.isOwner && _this.leaveProject(); }, title: perms.isOwner ? "Owners of projects are unable to leave." : "" }, "Leave Project")),
                    React.createElement("h4", { className: "h4" }, "Members"),
                    React.createElement(Participants, { participants: details ? details.Participants : null, introMessage: "", emptyMessage: "There are no members participating in this project.", showRole: true }),
                    details && perms.invite && (React.createElement("div", { className: "mt-5" },
                        React.createElement(InviteForm, { project: details })))))
            },
            {
                label: "Voting QR Code",
                content: qrCode ? (React.createElement("div", { style: { width: "50%", margin: "0 auto" } },
                    React.createElement("img", { style: { width: "100%", height: "auto" }, src: this.blobUrl, alt: "" }),
                    React.createElement("div", { className: "text-center" },
                        React.createElement("button", { className: "btn btn-sm btn-success", onClick: this.downloadQR }, "Download QR")))) : (React.createElement(Loading, { isFullScreen: false }))
            }
        ];
        var tabListExtra = (React.createElement("div", { className: "react-tabs__tab ml-auto" },
            perms.join && (React.createElement("div", { className: "btn btn-sm btn-success", onClick: function () { return _this.joinProject(); } }, "Join Project")),
            perms.edit && (React.createElement(Link, { to: "".concat(document.location.pathname, "edit/"), className: "btn btn-sm btn-success" }, "Edit")),
            perms.vote && (React.createElement(Link, { to: "".concat(RouteBuilder("", this.params.orgId, this.params.eventId, this.params.projectId), "vote/"), className: "btn btn-primary btn-sm" }, "Vote for project!"))));
        return (React.createElement("div", null,
            React.createElement(PageHeader, { title: details !== null ? details.Name : null, logo: details !== null ? details.LogoUrl : null, orgId: this.params.orgId, eventId: this.params.eventId }),
            React.createElement(InviteBar, __assign({}, this.params)),
            React.createElement("div", { className: "container" },
                React.createElement("p", { className: "lead" }, details ? details.Description : React.createElement(Skeleton, null)),
                React.createElement(ControlledTabs, { tabs: tabs, tabListExtra: tabListExtra }),
                React.createElement("div", { className: "text-right mt-2 mb-5" },
                    React.createElement(Link, { to: "".concat(RouteBuilder("", this.params.orgId, this.params.eventId), "?t=Projects"), className: "btn btn-light" }, "Back to Projects")))));
    };
    return Project;
}(React.PureComponent));
export default Project;
