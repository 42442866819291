var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { NotificationType } from "../models";
import uuidv1 from "uuid";
var Notification = /** @class */ (function (_super) {
    __extends(Notification, _super);
    function Notification() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            notifications: []
        };
        return _this;
    }
    Notification.prototype.componentDidMount = function () {
        var _this = this;
        document.body.addEventListener("notify", function (e) {
            var notificationsList = _this.state.notifications.map(function (item) { return item; });
            var id = uuidv1();
            var newNotification = __assign(__assign({}, e.detail), { id: id });
            notificationsList.push(newNotification);
            _this.setState({ notifications: notificationsList }, function () { return _this.delayAndRemove(id, newNotification.timeout); });
        });
    };
    Notification.prototype.delayAndRemove = function (id, delay) {
        var _this = this;
        if (delay === void 0) { delay = 2500; }
        setTimeout(function () {
            var notifications = _this.state.notifications.filter(function (n) { return n.id !== id; });
            _this.setState({ notifications: notifications });
        }, delay);
    };
    Notification.prototype.classNameFromType = function (type) {
        var className = "";
        switch (type) {
            case NotificationType.Fail:
                className = "danger";
                break;
            case NotificationType.Success:
                className = "success";
                break;
            default:
                className = "info";
        }
        return className;
    };
    Notification.prototype.render = function () {
        var _this = this;
        var notifications = this.state.notifications;
        var items = notifications.map(function (item) { return item; });
        items.reverse();
        return (React.createElement("div", { className: "notifications" }, items.map(function (n, i) {
            return (React.createElement("div", { key: n.id, style: { top: "".concat(i * 60, "px") }, className: "mt-4 alert alert-".concat(_this.classNameFromType(n.type)), role: "alert" }, n.message));
        })));
    };
    return Notification;
}(React.PureComponent));
export default Notification;
