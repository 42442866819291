import React from "react";
var Skeleton = function (props) {
    var className = props.class || "";
    return (React.createElement("span", { className: "sk ".concat(className && "sk--".concat(className)), style: { width: props.width && "".concat(props.width, "%"), height: "".concat(props.height ? "".concat(props.height, "px") : "auto") } }));
};
export var MarkdownSkeleton = function () {
    return (React.createElement("div", null,
        React.createElement("h1", { className: "h1" },
            React.createElement(Skeleton, { width: 50 })),
        React.createElement("p", null,
            React.createElement(Skeleton, null),
            React.createElement(Skeleton, null),
            React.createElement(Skeleton, null),
            React.createElement(Skeleton, null),
            React.createElement(Skeleton, null),
            React.createElement(Skeleton, { width: 25 }))));
};
export var CardsSkeleton = function (props) {
    var className = props.className;
    return (React.createElement("ul", { className: "cards ".concat(className ? "cards--".concat(className) : "", " ") },
        React.createElement("li", { className: "cards__item" },
            React.createElement("div", { className: "cards__inner" },
                React.createElement(Skeleton, null))),
        React.createElement("li", { className: "cards__item" },
            React.createElement("div", { className: "cards__inner" },
                React.createElement(Skeleton, null))),
        React.createElement("li", { className: "cards__item" },
            React.createElement("div", { className: "cards__inner" },
                React.createElement(Skeleton, null))),
        React.createElement("li", { className: "cards__item" },
            React.createElement("div", { className: "cards__inner" },
                React.createElement(Skeleton, null)))));
};
export default Skeleton;
