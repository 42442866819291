import React from "react";
import { NavLink } from "react-router-dom";
var AccountNavNew = function (props) {
    if (!props.user)
        return null;
    var user = props.user;
    return (React.createElement("nav", { className: "accountNavNew" },
        React.createElement("a", { className: "accountNavNew__link", href: "https://cargroup.enterprise.slack.com/archives/C06E6B6CHFU", target: "_blank" }, "#slack-channel"),
        React.createElement(NavLink, { className: "accountNavNew__link", to: "/faqs/" }, "FAQs"),
        React.createElement(NavLink, { className: "accountNavNew__account", to: "/profile/" },
            React.createElement("img", { src: require("../../images/account.svg").default }),
            React.createElement("span", { className: "accountNavNew__hiddenText" }, "Account")),
        React.createElement(NavLink, { className: "accountNavNew__signOut", to: "/signout/" },
            React.createElement("img", { src: require("../../images/logout.svg").default }),
            React.createElement("span", { className: "accountNavNew__hiddenText" }, "Sign Out"))));
};
export default AccountNavNew;
