module.exports = [
    {
        question: "What is a Hackathon?",
        answer: "A competitive event where teams or individuals come together to develop creative and innovative solutions to a specific problem or challenge within a set time frame - 72 hours."
    },
    {
        question: "Who can participate in a hackathon?",
        answer: "Everyone! Hackathons are open to all, not just for technical people. Anyone within the business can participate."
    },
    {
        question: "How do I register and join a team?",
        answer: "To register your idea, please visit the Hack Gorilla portal and create a project. Afterward, team members can join your project. If the team is 'invite only' then please contact the project owner to join the team."
    },
    {
        question: "Is there a limit to the number of participants in a team?",
        answer: "Teams are limited to a maximum of 10 people."
    },
    {
        question: "Are you able to be in multiple teams?",
        answer: "No, you are only allowed to join one team."
    },
    {
        question: "How can I stay updated during the hackathon?",
        answer: "Follow the event's official communication channels, such as email updates and slack channels. The names of these slack channels will be communicated before the hackathon begins."
    },
    {
        question: "Is the Hackathon in the office or remotely?",
        answer: "This is a virtual first event, but there is an option for you and your teams to work together in the office if you prefer in-person collaboration. All events will be conducted remotely via zoom."
    },
    {
        question: "How do I register for a swag box?",
        answer: "Stay tuned for updates regarding the swag box as the event approaches. To receive one, please make sure to register your name and address. Link will be released closer to the event. **Please note that registering to a team on Hack Gorilla does NOT automatically sign you up for the swag box."
    },
    {
        question: "When can we start working on our projects?",
        answer: "Teams can begin working on their projects after the opening ceremony."
    },
    {
        question: "How can we submit a video?",
        answer: "While a video is not required, you can submit one to help sell your idea. You do this in your team's Hackgorilla page. Please keep the video to 2 mins or less. Go to the 'project' tab and click on your teams project. On the right, there will be an 'Edit' button if you have the correct access. Once you have clicked the 'Edit' button, scroll down to the Project Overview and click the button 'Insert Media' button on the right."
    },
    {
        question: "What happens after the Hackathon?",
        answer: "We have a dedicated team that evaluates the hackathon ideas to determine their potential for production and implementation."
    },
];
