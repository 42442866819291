var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { Loading } from "../components";
import RouteBuilder from "../utils/RouteBuilder";
import ApiRoutes from "../utils/ApiRoutes";
import { AxiosStrict } from "../utils/Axios";
import { VotingState } from "../models/Voting";
var EventResults = /** @class */ (function (_super) {
    __extends(EventResults, _super);
    function EventResults() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isMounted = false;
        _this.state = {
            data: null,
            tick: 0
        };
        _this.refresh = null;
        return _this;
    }
    EventResults.prototype.componentDidMount = function () {
        this._isMounted = true;
        this.fetchVotingData();
    };
    EventResults.prototype.componentWillUnmount = function () {
        this._isMounted = false;
        clearTimeout(this.refresh);
    };
    EventResults.prototype.fetchVotingData = function () {
        var _this = this;
        var _a = this.props, orgId = _a.orgId, eventId = _a.eventId;
        var tick = this.state.tick;
        AxiosStrict()
            .get(RouteBuilder(ApiRoutes.Events, orgId, eventId, "reports/votes"))
            .then(function (res) {
            if (res.status === 200) {
                var data = res.data.Report;
                if (_this._isMounted) {
                    _this.setState({ data: data, tick: tick });
                    // if (data.Status !== VotingState.Completed) {
                    //   this.refresh = setTimeout(() => this.fetchVotingData(), 1000);
                    // }
                }
            }
        });
    };
    EventResults.prototype.render = function () {
        var data = this.state.data;
        if (!data)
            return React.createElement(Loading, null);
        var Status = data.Status, Start = data.Start, End = data.End, Results = data.Results;
        return (React.createElement("div", null,
            Status === VotingState.Completed && React.createElement("h1", { className: "h1 scores__winners" }, "Here are your winners..."),
            Status !== VotingState.Closed && (React.createElement("div", { className: "scores" }, Results.map(function (res, index) {
                return (React.createElement("div", { className: "scores__row scores__row--".concat(res.Rank), key: res.Name },
                    React.createElement("div", { className: "scores__place" }, res.Rank <= 3 ? (React.createElement("div", { className: "scores__medal", style: { backgroundImage: "url(".concat(require("../../images/medal".concat(res.Rank, ".svg")), ")") } })) : (res.Rank)),
                    React.createElement("div", { className: "scores__team" }, res.Name),
                    React.createElement("div", { className: "scores__count" }, res.Votes)));
            })))));
    };
    return EventResults;
}(React.PureComponent));
export default EventResults;
