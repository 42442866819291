export var CustomEventPolyfill = function () {
    if (typeof window["CustomEvent"] === "function")
        return false;
    function createCustomEvent(event, params) {
        if (params === void 0) { params = { bubbles: false, cancelable: false, detail: undefined }; }
        return new CustomEvent(event, params);
    }
    createCustomEvent.prototype = window["Event"].prototype;
    window["CustomEvent"] = CustomEvent;
};
export default CustomEventPolyfill;
