var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import UrlParams from "../utils/UrlParams";
var ControlledTabs = /** @class */ (function (_super) {
    __extends(ControlledTabs, _super);
    function ControlledTabs() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            active: _this.tabIndexFromQueryString
        };
        _this.updateUrl = function () { };
        return _this;
    }
    Object.defineProperty(ControlledTabs.prototype, "activeTab", {
        get: function () {
            return this.props.tabs[this.state.active];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ControlledTabs.prototype, "tabIndexFromQueryString", {
        get: function () {
            var tab = UrlParams.get("t") || null;
            if (tab === null)
                return 0;
            var match = this.props.tabs.findIndex(function (t) { return t.label.toLowerCase().trim() === tab.toLowerCase().trim(); });
            return match > 0 ? match : 0;
        },
        enumerable: false,
        configurable: true
    });
    ControlledTabs.prototype.componentDidMount = function () {
        if (this.state.active === 0) {
            window.history.replaceState("", "", "?t=".concat(this.props.tabs[0].label));
        }
    };
    ControlledTabs.prototype.componentWillUpdate = function (p, s) {
        var active = this.tabIndexFromQueryString;
        if (s.active !== active) {
            this.setState({ active: active });
        }
    };
    ControlledTabs.prototype.render = function () {
        var _this = this;
        var TabListExtra = function () { return _this.props.tabListExtra || null; };
        return (React.createElement(Tabs, { selectedIndex: this.state.active, onSelect: function (active) {
                _this.setState({ active: active }, function () { return window.history.pushState("", "", "?t=".concat(_this.activeTab.label)); });
            } },
            React.createElement(TabList, null,
                this.props.tabs.map(function (t) { return (React.createElement(Tab, { key: "th-".concat(t.label) }, t.label)); }),
                React.createElement(TabListExtra, null)),
            this.props.tabs.map(function (t) { return (React.createElement(TabPanel, { key: "tp-".concat(t.label) }, t.content)); })));
    };
    return ControlledTabs;
}(React.PureComponent));
export default ControlledTabs;
