import axios from "axios";
import Cookies from "js-cookie";
import cookieName from "./cookieName";
var getCookie = function () {
    var cookie = Cookies.get(cookieName) || null;
    if (cookie) {
        return JSON.parse(cookie).token;
    }
    return null;
};
var unauthorised = function () {
    Cookies.remove(cookieName);
    window.location.href = "/error/401/?from=".concat(window.location.href);
};
//Axios instance which add creds
export var AxiosFlexible = function () {
    var ax = axios.create({
        withCredentials: true,
        headers: { Authorization: "Bearer ".concat(getCookie()) }
    });
    return ax;
};
//Axios instance which add creds & strictly redirects on 401
export var AxiosStrict = function () {
    var ax = AxiosFlexible();
    ax.interceptors.response.use(function (response) {
        if (response.status === 401) {
            unauthorised();
        }
        return response;
    }, function (error) {
        if (error.response.status === 401) {
            unauthorised();
        }
        else {
            window.location.href = "/error/".concat(error.response.status, "/?from=").concat(window.location.href);
        }
        return Promise.reject(error);
    });
    return ax;
};
