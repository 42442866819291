var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { FailNotification, SuccessNotification } from "../utils/NotificationEvent";
import RouteBuilder from "../utils/RouteBuilder";
import ApiRoutes from "../utils/ApiRoutes";
import { AxiosFlexible } from "../utils/Axios";
var InviteForm = /** @class */ (function (_super) {
    __extends(InviteForm, _super);
    function InviteForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            email: "",
            isLoading: false
        };
        _this.handleSubmit = function (e) {
            e.preventDefault();
            if (!_this.isEmailValid(_this.state.email.trim())) {
                return FailNotification("Enter a valid email address.");
            }
            var _a = _this.props.project, OrganisationId = _a.OrganisationId, EventId = _a.EventId, Id = _a.Id;
            _this.setState({ isLoading: true });
            AxiosFlexible()
                .post("".concat(RouteBuilder(ApiRoutes.ProjectInvite, OrganisationId, EventId, Id)), {
                email: _this.state.email.trim()
            })
                .then(function (res) {
                if (res.status === 200) {
                    SuccessNotification("Success, invite has been sent.");
                    _this.setState({ isLoading: false, email: "" });
                }
                else {
                    FailNotification("Error sending invite.");
                    _this.setState({ isLoading: false });
                }
            })
                .catch(function (err) {
                FailNotification("Error sending invite.");
                _this.setState({ isLoading: false });
            });
        };
        return _this;
    }
    Object.defineProperty(InviteForm.prototype, "canSubmit", {
        get: function () {
            var enable = true;
            if (!this.isEmailValid(this.state.email.trim())) {
                enable = false;
            }
            if (this.state.isLoading) {
                enable = false;
            }
            return enable;
        },
        enumerable: false,
        configurable: true
    });
    InviteForm.prototype.isEmailValid = function (value) {
        if (!RegExp(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/).test(value)) {
            return false;
        }
        return true;
    };
    InviteForm.prototype.render = function () {
        var _this = this;
        var email = this.state.email;
        return (React.createElement("div", null,
            React.createElement("h5", { className: "h5" }, "Send an invite"),
            React.createElement("p", null, "Invite someone to help with this project."),
            React.createElement("form", { className: "form-inline", onSubmit: this.handleSubmit, noValidate: true },
                React.createElement("div", { className: "form-group mr-1" },
                    React.createElement("label", { className: "sr-only", htmlFor: "email" }, "Email:"),
                    React.createElement("input", { className: "form-control", type: "email", name: "email", value: email, placeholder: "email@address.com", onChange: function (e) {
                            _this.setState({ email: e.target.value });
                        } })),
                React.createElement("button", { type: "submit", className: "btn btn-primary", disabled: !this.canSubmit }, "Invite"))));
    };
    return InviteForm;
}(React.PureComponent));
export default InviteForm;
