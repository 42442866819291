var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { MediaType } from "../models/Media";
import { FailNotification } from "../utils/NotificationEvent";
var MediaItemStatus;
(function (MediaItemStatus) {
    MediaItemStatus["Loading"] = "Loading";
    MediaItemStatus["Error"] = "Error";
    MediaItemStatus["Processing"] = "Processing";
    MediaItemStatus["Processed"] = "Processed";
    MediaItemStatus["Ready"] = "Ready";
})(MediaItemStatus || (MediaItemStatus = {}));
var MediaLibraryItem = /** @class */ (function (_super) {
    __extends(MediaLibraryItem, _super);
    function MediaLibraryItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            status: MediaItemStatus.Loading
        };
        _this.videoCheckTimeout = null;
        return _this;
    }
    Object.defineProperty(MediaLibraryItem.prototype, "thumbnail", {
        get: function () {
            var _a = this.props.mediaItem, Link = _a.Link, Type = _a.Type;
            var status = this.state.status;
            if (Type === MediaType.Image) {
                return Link;
            }
            if (status === MediaItemStatus.Processed || status === MediaItemStatus.Ready) {
                return "".concat(Link, "?vxc_format=jpg");
            }
            //Return null if video status isn't processed or ready so we don't render the
            //image element src early and fire the onLoad before it has the right resource
            return null;
        },
        enumerable: false,
        configurable: true
    });
    MediaLibraryItem.prototype.componentWillMount = function () {
        if (this.props.mediaItem.Type === MediaType.Video) {
            this.checkVideoStatus();
        }
    };
    MediaLibraryItem.prototype.checkVideoStatus = function () {
        var _this = this;
        fetch(this.props.mediaItem.Link)
            .then(function (res) {
            if (res.status === 200) {
                console.log("".concat(_this.state.status, " => Processed"));
                if (_this.state.status !== MediaItemStatus.Ready) {
                    //To avoid race condition if image onload resolves first and sets to ready
                    _this.setState({ status: MediaItemStatus.Processed });
                }
            }
            else if (res.status === 202) {
                _this.setState({ status: MediaItemStatus.Processing });
                console.log("".concat(_this.state.status, " => Processing"));
                _this.videoCheckTimeout = setTimeout(function () { return _this.checkVideoStatus(); }, 1000);
            }
            else {
                throw new Error();
            }
        })
            .catch(function (e) {
            console.log("".concat(_this.state.status, " => Error"));
            _this.setState({ status: MediaItemStatus.Error });
        });
    };
    MediaLibraryItem.prototype.componentWillUnmount = function () {
        clearTimeout(this.videoCheckTimeout);
    };
    MediaLibraryItem.prototype.renderStatus = function () {
        var status = this.state.status;
        var statusImg = "";
        var statusText = "";
        switch (status) {
            case "Processing":
                statusImg = require("../../images/processing.gif");
                statusText = "Processing";
                break;
            case "Error":
                statusImg = require("../../images/alert.png");
                statusText = "Error";
                break;
            case "Loading":
            case "Processed":
            default:
                statusImg = require("../../images/img-loading.gif");
                statusText = "Loading";
                break;
        }
        return (React.createElement("div", { className: "mediaLib__status" },
            React.createElement("img", { src: statusImg, width: "32", height: "32" }),
            React.createElement("span", null, statusText)));
    };
    MediaLibraryItem.prototype.handleClick = function () {
        var _a = this.props, onClick = _a.onClick, mediaItem = _a.mediaItem;
        var status = this.state.status;
        if (status === MediaItemStatus.Error ||
            status === MediaItemStatus.Loading ||
            status === MediaItemStatus.Processing) {
            FailNotification("Cannot select item. (Media ".concat(status, ")"));
            return;
        }
        if (onClick) {
            onClick(mediaItem);
        }
    };
    MediaLibraryItem.prototype.render = function () {
        var _this = this;
        var status = this.state.status;
        return (React.createElement("div", { className: "mediaLib__item ".concat(status), onClick: function (e) { return _this.handleClick(); } },
            this.renderStatus(),
            React.createElement("div", { className: "mediaLib__itemContent", style: { backgroundImage: "url(".concat(this.thumbnail, ")") } },
                React.createElement("img", { className: "mediaLib__hiddenImgForLoading", src: this.thumbnail, onLoad: function (e) { return _this.setState({ status: MediaItemStatus.Ready }); }, onError: function () { return _this.setState({ status: MediaItemStatus.Error }); } }))));
    };
    return MediaLibraryItem;
}(React.PureComponent));
export default MediaLibraryItem;
