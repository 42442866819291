var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Route } from "react-router";
import { Loading } from "../components";
import Cookies from "js-cookie";
import AuthService from "../utils/AuthService";
import cookieName from "../utils/cookieName";
var PrivateRoute = /** @class */ (function (_super) {
    __extends(PrivateRoute, _super);
    function PrivateRoute() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.auth = new AuthService();
        _this.state = {
            isAuthenticated: null
        };
        return _this;
    }
    PrivateRoute.prototype.componentDidMount = function () {
        if (Cookies.get(cookieName)) {
            this.setState({ isAuthenticated: true }, function () {
                var evt = new CustomEvent("getUserDetails", {
                    detail: {}
                });
                document.body.dispatchEvent(evt);
            });
        }
        else {
            this.auth.login(location.href);
        }
    };
    PrivateRoute.prototype.render = function () {
        var isAuthenticated = this.state.isAuthenticated;
        var _a = this.props, Component = _a.component, rest = __rest(_a, ["component"]);
        if (!isAuthenticated) {
            return React.createElement(Loading, null);
        }
        return React.createElement(Route, __assign({}, rest, { component: Component }));
    };
    return PrivateRoute;
}(Route));
export default PrivateRoute;
