var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { PageHeader, MarkdownRender, MarkdownSkeleton, Participants, ProjectList, InviteBar, ControlledTabs, TeamsBreakdown, CountdownTimer } from "../components";
import { RoleType } from "../models";
import ApiRoutes from "../utils/ApiRoutes";
import RouteBuilder from "../utils/RouteBuilder";
import { Link } from "react-router-dom";
import { AxiosStrict } from "../utils/Axios";
import EventResults from "./EventResults";
var EventState;
(function (EventState) {
    EventState["Closed"] = "Closed";
    EventState["Started"] = "Started";
    EventState["VotingOpen"] = "VotingOpen";
    EventState["VotingCompleted"] = "VotingCompleted";
    EventState["Completed"] = "Completed";
})(EventState || (EventState = {}));
var Event = /** @class */ (function (_super) {
    __extends(Event, _super);
    function Event() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            details: null,
            userPermissions: null
        };
        return _this;
    }
    Object.defineProperty(Event.prototype, "params", {
        get: function () {
            return this.props.match.params;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Event.prototype, "currentEventState", {
        get: function () {
            var details = this.state.details;
            if (!details) {
                return null;
            }
            var nowTime = new Date();
            var eventStartTime = new Date(details.Dates.EventStart);
            var eventEndTime = new Date(details.Dates.EventEnd);
            var voteStartTime = new Date(details.Dates.VotingStart);
            var voteEndTime = new Date(details.Dates.VotingEnd);
            if (nowTime.getTime() > eventEndTime.getTime()) {
                return EventState.Completed;
            }
            if (nowTime.getTime() > voteEndTime.getTime()) {
                return EventState.VotingCompleted;
            }
            if (nowTime.getTime() > voteStartTime.getTime()) {
                return EventState.VotingOpen;
            }
            if (nowTime.getTime() > eventStartTime.getTime()) {
                return EventState.Started;
            }
            return EventState.Closed;
        },
        enumerable: false,
        configurable: true
    });
    Event.prototype.componentDidMount = function () {
        document.title = "Event | Hackathon Portal";
        this.fetchEventData();
    };
    Event.prototype.fetchPermissions = function () {
        var _this = this;
        AxiosStrict()
            .get("".concat(ApiRoutes.UserEvents, "/").concat(this.params.orgId, "/").concat(this.params.eventId))
            .then(function (res) {
            if (res.status === 200) {
                document.title = "".concat(res.data.Event.Name, " | Hackathon Portal");
                _this.setState({ userPermissions: res.data.Event });
            }
        });
    };
    Event.prototype.fetchEventData = function () {
        var _this = this;
        AxiosStrict()
            .get(RouteBuilder(ApiRoutes.Events, this.params.orgId, this.params.eventId))
            .then(function (res) {
            if ((res.status = 200)) {
                // res.data.Event.Dates = {
                //   EventEnd: "2020-07-31T00:30:00Z",
                //   EventStart: "2020-07-28T23:00:00Z",
                //   VotingEnd: "2020-08-02T23:30:00Z",
                //   VotingStart: "2020-07-31T00:30:00Z"
                // };
                _this.setState({ details: res.data.Event });
                _this.fetchPermissions();
            }
        });
    };
    Event.prototype.permissions = function () {
        var userPermissions = this.state.userPermissions;
        var permissions = {
            create: false,
            edit: false,
            breakdown: false,
            results: false
        };
        if (userPermissions) {
            permissions.create = userPermissions.Permissions.includes("project_create");
            permissions.edit = userPermissions.Permissions.includes("event_edit");
            permissions.breakdown = userPermissions.Permissions.includes("event_reports");
            permissions.results = userPermissions.Permissions.includes("event_reports");
        }
        return permissions;
    };
    Event.prototype.render = function () {
        var details = this.state.details;
        var eventState = this.currentEventState;
        var perms = this.permissions();
        var participants = details ? details.Participants.filter(function (p) { return p.Role !== RoleType.Guest; }) : null;
        var guests = details ? details.Participants.filter(function (p) { return p.Role === RoleType.Guest; }) : null;
        var tabs = [
            {
                label: "Overview",
                content: this.state.details ? React.createElement(MarkdownRender, { raw: this.state.details.Content }) : React.createElement(MarkdownSkeleton, null)
            },
            {
                label: "Projects",
                content: (React.createElement("div", null,
                    perms.create && (React.createElement(Link, { to: "".concat(document.location.pathname, "create"), className: "btn btn-success btn-sm abs-r" }, "Create Project")),
                    React.createElement(ProjectList, { projects: details ? details.Projects : null })))
            },
            {
                label: "Participants",
                content: (React.createElement("div", null,
                    React.createElement("h4", { className: "h4" }, "Participants"),
                    React.createElement(Participants, { participants: participants, introMessage: "The following people are participants to this event:", emptyMessage: "There is no one participating at this event.", showRole: true })))
            },
            {
                label: "Guests",
                content: (React.createElement("div", null,
                    React.createElement("h4", { className: "h4" }, "Guests"),
                    React.createElement(Participants, { participants: guests, introMessage: "The following guests are attending this event:", emptyMessage: "There are no guests attending this event." })))
            }
        ];
        if (perms.breakdown && details)
            tabs.push({
                label: "Team Breakdown",
                content: React.createElement(TeamsBreakdown, __assign({}, this.params, { projects: details.Projects }))
            });
        if (perms.results && details)
            tabs.push({
                label: "Results",
                content: React.createElement(EventResults, __assign({}, this.params))
            });
        var tabListExtra = (React.createElement("div", { className: "react-tabs__tab ml-auto" }, perms.edit && (React.createElement(Link, { to: "".concat(document.location.pathname, "edit/"), className: "btn btn-sm btn-success" }, "Edit"))));
        return (React.createElement("div", null,
            React.createElement(PageHeader, { title: details !== null ? details.Name : null, logo: details !== null ? details.LogoUrl : null, orgId: this.params.orgId, color: details !== null ? details.HeaderColour : null },
                eventState === EventState.Closed && (React.createElement(CountdownTimer, { message: "Event starts in:", dateTime: details ? details.Dates.EventStart : null })),
                eventState === EventState.Started && (React.createElement(CountdownTimer, { message: "Voting starts in:", dateTime: details ? details.Dates.VotingStart : null })),
                eventState === EventState.VotingOpen && (React.createElement(CountdownTimer, { message: "Voting closes in:", dateTime: details ? details.Dates.VotingEnd : null }))),
            React.createElement(InviteBar, __assign({}, this.params)),
            React.createElement("div", { className: "container" },
                React.createElement(ControlledTabs, { tabs: tabs, tabListExtra: tabListExtra }),
                React.createElement("div", { className: "text-right mt-2 mb-5" },
                    React.createElement(Link, { to: "".concat(RouteBuilder("", this.params.orgId), "?t=Events"), className: "btn btn-light" }, "Back to Events")))));
    };
    return Event;
}(React.PureComponent));
export default Event;
