var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import ReactPlayer from "react-player";
var DashboardVideoPlayerNew = /** @class */ (function (_super) {
    __extends(DashboardVideoPlayerNew, _super);
    function DashboardVideoPlayerNew() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            videoSource: "https://hackgorilla.vxcrush.net/ugc/5s0sra6fqkozmkwnzrwgewnr4.mp4",
            isModalVisible: false
        };
        return _this;
    }
    DashboardVideoPlayerNew.prototype.render = function () {
        // TODO: If we want to have a pop up modal when the image is clicked on small screens
        // we may need to add a click listener that listens to when the video is clicked
        // and add modal to present on top of the whole screen
        var _a = this.state, videoSource = _a.videoSource, isModalVisible = _a.isModalVisible;
        return (React.createElement("div", { className: "dashboardVideoPlayerNew" },
            React.createElement(ReactPlayer, { className: 'dashboardVideoPlayerNew__inner', url: videoSource, width: "100%", controls: true, config: {
                    file: {
                        forceHLS: !(/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
                    }
                } })));
    };
    return DashboardVideoPlayerNew;
}(React.PureComponent));
export default DashboardVideoPlayerNew;
