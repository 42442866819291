import ApiRoutes from "./ApiRoutes";
import RouteBuilder from "./RouteBuilder";
import { AxiosFlexible } from "../utils/Axios";
export var getOrganisationById = function (orgId) {
    return new Promise(function (resolve, reject) {
        AxiosFlexible()
            .get(RouteBuilder(ApiRoutes.Organisation, orgId))
            .then(function (res) {
            if (res.status === 200) {
                resolve(res.data.Organisation);
            }
            else {
                reject(null);
            }
        })
            .catch(function (err) { return reject(null); });
    });
};
export var getEventByIdAndOrgId = function (orgId, eventId) {
    return new Promise(function (resolve, reject) {
        AxiosFlexible()
            .get(RouteBuilder(ApiRoutes.Events, orgId, eventId))
            .then(function (res) {
            if (res.status === 200) {
                resolve(res.data.Event);
            }
            else {
                reject(null);
            }
        })
            .catch(function (err) { return reject(null); });
    });
};
export var getProjectByIdAndEventIdAndOrgId = function (orgId, eventId, projectId) {
    return new Promise(function (resolve, reject) {
        AxiosFlexible()
            .get(RouteBuilder(ApiRoutes.Projects, orgId, eventId, projectId))
            .then(function (res) {
            if (res.status === 200) {
                resolve(res.data.Project);
            }
            else {
                reject(null);
            }
        })
            .catch(function (err) { return reject(null); });
    });
};
export var getInvitations = function () {
    return new Promise(function (resolve, reject) {
        AxiosFlexible()
            .get("".concat(ApiRoutes.UserInvitations))
            .then(function (res) {
            if (res.status === 200) {
                resolve(res.data.Invitations);
            }
            else {
                reject(null);
            }
        })
            .catch(function (err) { return reject(null); });
    });
};
var invitationAction = function (id, action) {
    return new Promise(function (resolve, reject) {
        AxiosFlexible()
            .post("".concat(ApiRoutes.UserInvitations, "/").concat(id, "/").concat(action), {})
            .then(function (res) {
            if (res.status === 200) {
                resolve();
            }
            else {
                reject(null);
            }
        })
            .catch(function (err) { return reject(null); });
    });
};
export var acceptInvitation = function (id) { return invitationAction(id, "accept"); };
export var rejectInvitation = function (id) { return invitationAction(id, "reject"); };
