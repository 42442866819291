export var InvitationTypes;
(function (InvitationTypes) {
    InvitationTypes["Organisation"] = "Organisation";
    InvitationTypes["Event"] = "Event";
    InvitationTypes["Project"] = "Project";
})(InvitationTypes || (InvitationTypes = {}));
export var InvitationStatus;
(function (InvitationStatus) {
    InvitationStatus["Pending"] = "Pending";
    InvitationStatus["Accept"] = "Accept";
    InvitationStatus["Reject"] = "Reject";
})(InvitationStatus || (InvitationStatus = {}));
