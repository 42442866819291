var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import { FailNotification, SuccessNotification } from "../utils/NotificationEvent";
import ApiRoutes from "../utils/ApiRoutes";
import { AxiosFlexible } from "../utils/Axios";
import { Loading } from "./";
import { MediaType } from "../models/Media";
import MediaLibraryItem from "./MediaLibraryItem";
var MediaLibrary = /** @class */ (function (_super) {
    __extends(MediaLibrary, _super);
    function MediaLibrary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.uploadToken = null;
        _this.validImageMimes = ["image/jpeg", "image/png"];
        _this.validVideoMimes = ["video/mp4", "video/quicktime"];
        _this.callbackOnClose = null;
        _this.dragCount = 0;
        _this.state = {
            media: [],
            isVisible: false,
            isDragging: false,
            isLoading: false,
            filter: MediaType.Image,
            canChangeFilter: true
        };
        _this._chunks = [];
        _this.uploadImage = function (image) { return __awaiter(_this, void 0, void 0, function () {
            var getUploadUrl, uploadImage, saveToUser, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ isLoading: true, filter: MediaType.Image });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, AxiosFlexible().get("".concat(ApiRoutes.UserMedia, "/upload"))];
                    case 2:
                        getUploadUrl = _a.sent();
                        return [4 /*yield*/, AxiosFlexible().post(getUploadUrl.data.UploadUrl, image, {
                                headers: {
                                    "Content-Type": image.type
                                }
                            })];
                    case 3:
                        uploadImage = _a.sent();
                        return [4 /*yield*/, AxiosFlexible().post("".concat(ApiRoutes.UserMedia), {
                                url: "".concat(ENV.pxCrushBase).concat(uploadImage.data.Filename)
                            })];
                    case 4:
                        saveToUser = _a.sent();
                        SuccessNotification("Success, your images was uploaded.");
                        this.fetchMedia();
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _a.sent();
                        FailNotification("Oops, that upload seems to have failed.", 3000);
                        this.fetchMedia();
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        _this.handleDrop = function (files) {
            var _a = _this.state, canChangeFilter = _a.canChangeFilter, filter = _a.filter;
            var canUploadVideo = canChangeFilter || filter === MediaType.Video;
            var canUploadImage = canChangeFilter || filter === MediaType.Image;
            if (files.length !== 1) {
                return FailNotification("Please upload one file at a time.");
            }
            if (_this.validImageMimes.filter(function (type) { return files[0].type === type; }).length > 0 && canUploadImage) {
                _this.uploadImage(files[0]);
                return;
            }
            if (_this.validVideoMimes.filter(function (type) { return files[0].type === type; }).length > 0 && canUploadVideo) {
                _this.handleVideoFile(files[0]);
                return;
            }
            return FailNotification("Please upload the correct file type.");
        };
        _this.onDrop = function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                _this.handleDrop(e.dataTransfer.files);
                // e.dataTransfer.clearData();
                _this.setState({ isDragging: false });
            }
        };
        _this.dragPrevent = function (e) {
            e.preventDefault();
            e.stopPropagation();
        };
        _this.onDragEnter = function (e) {
            e.preventDefault();
            e.stopPropagation();
            _this.dragCount++;
            _this.setState({ isDragging: true });
        };
        _this.onDragLeave = function (e) {
            e.preventDefault();
            e.stopPropagation();
            _this.dragCount--;
            if (_this.dragCount < 1)
                _this.setState({ isDragging: false });
        };
        _this.close = function (selected) {
            if (selected === void 0) { selected = null; }
            if (_this.state.isVisible && !_this.state.isLoading) {
                _this.setState({ media: [], isVisible: false, isDragging: false, isLoading: false });
                if (_this.callbackOnClose) {
                    _this.callbackOnClose(selected);
                    _this.callbackOnClose = null;
                }
            }
        };
        return _this;
    }
    MediaLibrary.prototype.componentDidMount = function () {
        // this.setState({ isVisible: true });
        // this.fetchMedia();
        var _this = this;
        document.body.addEventListener("keydown", function (e) { return e.keyCode === 27 && _this.close(); });
        document.body.addEventListener("MediaLibraryShow", function (e) {
            var _a = e.detail, callbackOnClose = _a.callbackOnClose, filter = _a.filter, canChangeFilter = _a.canChangeFilter;
            _this.setState({ media: [], isVisible: true, isDragging: false, isLoading: false, filter: filter, canChangeFilter: canChangeFilter });
            _this.callbackOnClose = callbackOnClose;
            _this.fetchMedia();
        });
        document.body.addEventListener("MediaLibraryHide", function (e) {
            _this.setState({ media: [], isVisible: false, isDragging: false, isLoading: false });
        });
    };
    MediaLibrary.prototype.fetchMedia = function () {
        var _this = this;
        this.setState({ isLoading: true });
        AxiosFlexible()
            .get("".concat(ApiRoutes.UserMedia))
            .then(function (res) {
            if (res.status === 200) {
                var media = res.data.Images.map(function (item) {
                    //Identify the type here
                    return __assign(__assign({}, item), { Type: item.Link.includes("vxcrush") ? MediaType.Video : MediaType.Image });
                });
                media.reverse(); //Flip so newest first
                _this.setState({ media: media, isLoading: false });
            }
            else {
                _this.setState({ media: null, isLoading: false });
                FailNotification("Unable to load your images.");
            }
        })
            .catch(function (e) {
            FailNotification("Unable to load your images.");
            _this.setState({ media: null, isLoading: false });
        });
    };
    MediaLibrary.prototype.uploadChunk = function (i, blob, start, end, uploadUrl) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._chunks[i] = null;
                fetch(uploadUrl, {
                    method: "post",
                    body: blob,
                    headers: { "Content-Type": "application/octet-stream" }
                }).then(function (response) {
                    response.json().then(function (j) { return (_this._chunks[i] = j); });
                });
                return [2 /*return*/];
            });
        });
    };
    MediaLibrary.prototype.uploadVideo = function (video, uploadUrl) {
        return __awaiter(this, void 0, void 0, function () {
            var chunkSize, start, end, i, blob;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        chunkSize = 5242880;
                        start = 0;
                        end = chunkSize;
                        if (end > video.size)
                            end = video.size;
                        i = 0;
                        while (1 == 1) {
                            console.log("start ".concat(start, ", end ").concat(end));
                            blob = video.slice(start, end);
                            this.uploadChunk(i, blob, start, end, uploadUrl);
                            i++;
                            if (end == video.size)
                                break; // video complete
                            start = end;
                            end = start + chunkSize;
                            if (end > video.size)
                                end = video.size;
                        }
                        console.log("Chunks", i);
                        _a.label = 1;
                    case 1:
                        if (!this._chunks.reduce(function (p, c) { return p || !c; }, false)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.sleep(100)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 1];
                    case 3: return [2 /*return*/, this._chunks];
                }
            });
        });
    };
    MediaLibrary.prototype.combineFile = function (chunks, contentType, combineUrl) {
        return __awaiter(this, void 0, void 0, function () {
            var response, combinedFile;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log(combineUrl);
                        console.log("Content-Type: ", contentType);
                        return [4 /*yield*/, fetch(combineUrl, {
                                method: "post",
                                body: JSON.stringify({
                                    "Content-Type": contentType,
                                    Files: chunks
                                }),
                                headers: { "Content-Type": "application/json" }
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        combinedFile = _a.sent();
                        console.log(combinedFile);
                        return [2 /*return*/, combinedFile.Filename];
                }
            });
        });
    };
    MediaLibrary.prototype.handleVideoFile = function (video) {
        return __awaiter(this, void 0, void 0, function () {
            var getUploadUrl, chunkFiles, crushfsRelativeUrl, vxcrushVideoUrl, saveToUser, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ isLoading: true, filter: MediaType.Video });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, AxiosFlexible().get("".concat(ApiRoutes.UserMedia, "/upload"))];
                    case 2:
                        getUploadUrl = _a.sent();
                        console.log("Chunking");
                        return [4 /*yield*/, this.uploadVideo(video, getUploadUrl.data.UploadUrl)];
                    case 3:
                        chunkFiles = _a.sent();
                        console.log("Combining");
                        return [4 /*yield*/, this.combineFile(chunkFiles, video.type, getUploadUrl.data.CombineUrl)];
                    case 4:
                        crushfsRelativeUrl = _a.sent();
                        vxcrushVideoUrl = "".concat(ENV.vxCrushBase).concat(crushfsRelativeUrl);
                        console.log("URL", vxcrushVideoUrl);
                        console.log("Saving to User");
                        return [4 /*yield*/, AxiosFlexible().post("".concat(ApiRoutes.UserMedia), {
                                url: vxcrushVideoUrl
                            })];
                    case 5:
                        saveToUser = _a.sent();
                        SuccessNotification("Success, your video was uploaded. Processing will start now.");
                        this.fetchMedia();
                        return [3 /*break*/, 7];
                    case 6:
                        e_2 = _a.sent();
                        FailNotification("Oops, that upload seems to have failed.", 3000);
                        this.fetchMedia();
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    MediaLibrary.prototype.clippy = function (item) {
        try {
            var clipInput = document.createElement("input");
            clipInput.style.opacity = "0";
            clipInput.style.position = "absolute";
            clipInput.value = item.Link;
            document.body.appendChild(clipInput);
            clipInput.select();
            document.execCommand("copy");
            document.body.removeChild(clipInput);
            SuccessNotification("Media link saved to clipboard.");
            this.close(item);
        }
        catch (e) {
            FailNotification("Unable to save to clipboard.");
        }
    };
    MediaLibrary.prototype.sleep = function (milliseconds) {
        return new Promise(function (resolve) { return setTimeout(resolve, milliseconds); });
    };
    MediaLibrary.prototype.reqs = function () {
        var defaultMsg = "JPG, PNG, MP4 and MOV files are permitted. Minimum 5mb and Maximum of 500mb (video only).";
        if (this.state.canChangeFilter) {
            return defaultMsg;
        }
        switch (this.state.filter) {
            case MediaType.Image:
                return "JPG and PNG files are permitted.";
                return;
            case MediaType.Video:
                return "MP4 and MOV files are permitted. Minimum 5mb and Maximum of 500mb.";
                break;
            default:
                return defaultMsg;
                break;
        }
    };
    MediaLibrary.prototype.render = function () {
        var _this = this;
        var _a = this.state, isVisible = _a.isVisible, isDragging = _a.isDragging, isLoading = _a.isLoading, filter = _a.filter, media = _a.media, canChangeFilter = _a.canChangeFilter;
        if (!isVisible)
            return null;
        return (React.createElement("section", { className: "mediaLib ".concat(isDragging ? "mediaLib--drag" : "", " "), onClick: function (e) { return e.target.classList.contains("mediaLib") && _this.close(); } },
            React.createElement("div", { className: "mediaLib__inner", onDragEnter: this.onDragEnter, 
                // onDragEnd={this.dragPrevent}
                onDrop: this.onDrop, onDragOver: this.dragPrevent, 
                // onDragOver={this.dragPrevent}
                onDragLeave: this.onDragLeave },
                React.createElement("div", { className: "mediaLib__content" },
                    React.createElement("div", { className: "mediaLib__close", onClick: function () { return _this.close(); } }),
                    React.createElement("div", { className: "mediaLib__scroll" },
                        React.createElement("header", { className: "mediaLib__header" },
                            React.createElement("h3", { className: "h3" }, "Media Library"),
                            canChangeFilter && (React.createElement("div", { className: "mediaLib__filters" },
                                React.createElement("em", null, "Filter by:"),
                                Object.keys(MediaType).map(function (f) { return (React.createElement("button", { key: f, className: "btn btn-sm ".concat(filter === f || !filter ? "btn-primary" : "btn-light"), onClick: function () { return _this.setState({ filter: MediaType[f] }); } },
                                    f,
                                    "s")); })))),
                        React.createElement("p", null, "You can view your uploaded media below. Click to select, or drag a new one here to upload."),
                        React.createElement("p", { className: "mediaLib__reqs" }, this.reqs()),
                        media === null && (React.createElement("p", { className: "text-center small" },
                            React.createElement("strong", null, "Unable to load your media right now."))),
                        media !== null && media.length < 1 && (React.createElement("p", { className: "text-center" },
                            React.createElement("em", null,
                                "You haven't uploaded anything yet.",
                                React.createElement("br", null),
                                "Drag here to upload your image or video."))),
                        media !== null && media.length > 0 && (React.createElement("div", { className: "mediaLib__items" }, media
                            .filter(function (item) { return item.Type === filter; })
                            .map(function (item) { return (React.createElement(MediaLibraryItem, { key: item.Id, mediaItem: item, onClick: function (e) {
                                if (_this.callbackOnClose) {
                                    _this.close(e);
                                    SuccessNotification("Item selected.");
                                }
                                else {
                                    _this.clippy(e);
                                }
                            } })); })))),
                    isLoading && (React.createElement("div", { className: "mediaLib__loading" },
                        React.createElement(Loading, { isFullScreen: false }))),
                    isDragging && React.createElement("div", { className: "mediaLib__dragging" }, "Drop your image or video here!")))));
    };
    return MediaLibrary;
}(React.PureComponent));
export default MediaLibrary;
