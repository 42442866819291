import * as React from "react";
import { CardsSkeleton } from ".";
import { Link } from "react-router-dom";
var cardInner = function (card) {
    return (React.createElement("div", { className: "eventcardsnew__innerWrap" },
        card.icon ?
            React.createElement("div", { className: "eventcardsnew__logo", style: { backgroundImage: "url(".concat(card.icon, ")") } })
            : React.createElement("div", { className: "eventcardsnew__logo", style: { backgroundImage: "url(\"../../images/loading.png\")" } }),
        React.createElement("div", { className: "eventcardsnew__content" },
            React.createElement("span", { className: "eventcardsnew__title" }, card.name),
            React.createElement("span", { className: "eventcardsnew__desc" }, card.description))));
};
var EventCardsNew = function (props) {
    var items = props.items, emptyMessage = props.emptyMessage, className = props.className;
    if (items === null) {
        return React.createElement(CardsSkeleton, { className: className });
    }
    if (items && !items.length)
        return (React.createElement("p", { className: "small" },
            React.createElement("em", null, emptyMessage)));
    return (React.createElement("div", { className: "eventcardsnew ".concat(className ? "eventcardsnew--".concat(className) : "", " ") }, items.map(function (card) {
        var itemClassName = ["eventcardsnew__item"];
        card.className && itemClassName.push(card.className);
        card.tag && itemClassName.push(card.tag);
        return (React.createElement("div", { className: itemClassName.join(" "), key: card.id }, card.link ? (React.createElement(Link, { className: "eventcardsnew__inner", to: card.link }, cardInner(card))) : (React.createElement("div", { className: "eventcardsnew__inner" }, cardInner(card)))));
    })));
};
export default EventCardsNew;
