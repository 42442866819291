var api = ENV.api;
var ApiRoutes = {
    ApiBase: "".concat(api, "/v1"),
    UserAuthenicate: "".concat(api, "/v1/u/authenticate"),
    UserDetails: "".concat(api, "/v1/u/details"),
    UserLogOut: "".concat(api, "/v1/u/logout"),
    ProjectInvite: "".concat(api, "/v1/u/invite/p"),
    UserInvitations: "".concat(api, "/v1/u/invitations"),
    UserOrganisations: "".concat(api, "/v1/u/access/o"),
    UserEvents: "".concat(api, "/v1/u/access/e"),
    UserProjects: "".concat(api, "/v1/u/access/p"),
    UserProjectLeave: "".concat(api, "/v1/u/leave/p"),
    UserProjectJoin: "".concat(api, "/v1/u/join/p"),
    UserVote: "".concat(api, "/v1/u/vote/p"),
    Organisation: "".concat(api, "/v1/o"),
    Events: "".concat(api, "/v1/e"),
    Projects: "".concat(api, "/v1/p"),
    UserMedia: "".concat(api, "/v1/u/images"),
    QRCode: "".concat(api, "/v1/qr.svg"),
    User: "".concat(api, "/v1/u")
};
export default ApiRoutes;
