var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { AxiosFlexible } from "../utils/Axios";
import ApiRoutes from "../utils/ApiRoutes";
import { Cards } from ".";
import RouteBuilder from "../utils/RouteBuilder";
var UserVoteList = /** @class */ (function (_super) {
    __extends(UserVoteList, _super);
    function UserVoteList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isMounted = false;
        _this.state = {
            votes: null,
            isError: false
        };
        return _this;
    }
    UserVoteList.prototype.componentDidMount = function () {
        this._isMounted = true;
        this.fetchInvitations();
    };
    UserVoteList.prototype.componentWillUnmount = function () {
        this._isMounted = false;
    };
    UserVoteList.prototype.setState = function (state) {
        this._isMounted && _super.prototype.setState.call(this, state);
    };
    UserVoteList.prototype.fetchInvitations = function () {
        var _this = this;
        AxiosFlexible()
            .get(ApiRoutes.UserVote)
            .then(function (res) {
            if (res.status) {
                _this.setState({ votes: res.data.Votes });
            }
            else {
                _this.setState({ isError: true });
            }
        })
            .catch(function (err) {
            _this.setState({ isError: true });
        });
    };
    UserVoteList.prototype.render = function () {
        var _a = this.state, votes = _a.votes, isError = _a.isError;
        if (isError) {
            return (React.createElement("p", { className: "small" },
                React.createElement("em", null,
                    "You haven't placed any votes.",
                    isError && "*")));
        }
        return (React.createElement(Cards, { emptyMessage: "You haven't placed any votes.", items: votes
                ? votes.map(function (vote) {
                    return {
                        id: vote.Id,
                        name: vote.Name,
                        icon: vote.LogoUrl || require("../../images/icon-project.png"),
                        link: "".concat(RouteBuilder("", vote.OrganisationId, vote.EventId, vote.Id), "vote/"),
                        description: vote.Description
                    };
                })
                : null }));
    };
    return UserVoteList;
}(React.PureComponent));
export default UserVoteList;
