var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { Link } from "react-router-dom";
import Skeleton from "./Skeleton";
import { AxiosStrict } from "../utils/Axios";
import ApiRoutes from "../utils/ApiRoutes";
import RouteBuilder from "../utils/RouteBuilder";
var UserOrganisationListNew = /** @class */ (function (_super) {
    __extends(UserOrganisationListNew, _super);
    function UserOrganisationListNew() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isMounted = false;
        _this.state = {
            organisations: null,
            hasError: false
        };
        _this.fetchUserData = function () {
            AxiosStrict()
                .get(ApiRoutes.UserOrganisations)
                .then(function (res) {
                if (res.status === 200) {
                    var organisations = res.data.Organisations;
                    _this.setState({ organisations: organisations });
                }
            })
                .catch(function (err) {
                _this.setState({ hasError: true });
            });
        };
        return _this;
    }
    UserOrganisationListNew.prototype.componentDidMount = function () {
        this.fetchUserData();
        this._isMounted = true;
    };
    UserOrganisationListNew.prototype.componentWillUnmount = function () {
        this._isMounted = false;
    };
    UserOrganisationListNew.prototype.setState = function (state) {
        this._isMounted && _super.prototype.setState.call(this, state);
    };
    UserOrganisationListNew.prototype.render = function () {
        var _a = this.state, organisations = _a.organisations, hasError = _a.hasError;
        if (hasError) {
            return (React.createElement("p", { className: "small" },
                React.createElement("em", null, "Couldn't load organisations.")));
        }
        if (organisations === null) {
            return (React.createElement("section", { className: "orgList" },
                React.createElement("h5", { className: "h5 orgList__title" }, "Organisations"),
                React.createElement("ul", { className: "orgList__list" },
                    React.createElement("li", null,
                        React.createElement(Skeleton, null)))));
        }
        return (React.createElement("section", { className: "orgList" },
            React.createElement("h5", { className: "h5 orgList__title" }, "Organisations"),
            React.createElement("ul", { className: "orgList__list" }, organisations.length ? (organisations.map(function (org) {
                return (React.createElement("li", { className: "orgList__listItem", key: "org-".concat(org.Id) },
                    React.createElement(Link, { to: RouteBuilder("", org.Id) },
                        React.createElement("img", { className: "orgList__itemLogo", src: org.LogoUrl || require("../../images/icon-org.png") }),
                        org.Name)));
            })) : (React.createElement("p", { className: "small" },
                React.createElement("em", null, "You aren't part of any organisation."))))));
    };
    return UserOrganisationListNew;
}(React.PureComponent));
export default UserOrganisationListNew;
