var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
var DeleteConfirm = /** @class */ (function (_super) {
    __extends(DeleteConfirm, _super);
    function DeleteConfirm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showConfirm: false
        };
        return _this;
    }
    DeleteConfirm.prototype.render = function () {
        var _this = this;
        if (this.state.showConfirm) {
            return (React.createElement("div", null,
                "Are you sure?",
                React.createElement("a", { href: "#", className: "ml-2 btn btn-danger", onClick: function (e) {
                        e.preventDefault();
                        _this.props.onConfirm();
                    } }, "Yes, delete it."),
                React.createElement("a", { href: "#", onClick: function (e) {
                        e.preventDefault();
                        _this.setState({ showConfirm: false });
                    }, className: "btn btn-text" }, "Cancel")));
        }
        return (React.createElement("a", { href: "#", className: "btn btn-danger", onClick: function (e) {
                e.preventDefault();
                _this.setState({ showConfirm: true });
            } }, "Delete"));
    };
    return DeleteConfirm;
}(React.PureComponent));
export default DeleteConfirm;
