var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import RandomContent from "../utils/RandomContent";
var ProjectMemberList = /** @class */ (function (_super) {
    __extends(ProjectMemberList, _super);
    function ProjectMemberList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectMemberList.prototype.render = function () {
        return (React.createElement("div", null,
            React.createElement("h5", { className: "h5 mb-3" }, "Contributing Members"),
            React.createElement("ul", null, RandomContent.members().map(function (member) { return (React.createElement("li", { key: "member-".concat(member) }, member)); }))));
    };
    return ProjectMemberList;
}(React.PureComponent));
export default ProjectMemberList;
