import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardsSkeleton } from ".";
import { CardActionArea, Typography } from '@mui/material';
var ProjectCardsNew = function (props) {
    var items = props.items, emptyMessage = props.emptyMessage, className = props.className;
    if (items === null) {
        return React.createElement(CardsSkeleton, { className: className });
    }
    if (items && !items.length)
        return (React.createElement("p", { className: "small" },
            React.createElement("em", null, emptyMessage)));
    return (React.createElement("div", { className: "projectCards ".concat(className ? "projectCards--".concat(className) : "", " ") }, items.map(function (card) {
        var itemClassName = ["projectCards__item"];
        card.className && itemClassName.push(card.className);
        card.tag && itemClassName.push(card.tag);
        return (React.createElement("div", { className: itemClassName.join(" "), key: card.id },
            React.createElement("a", { style: { textDecoration: 'none' }, href: card.link },
                React.createElement(Card, { sx: {
                        display: 'flex',
                        height: '90px',
                        borderRadius: '10px'
                    } },
                    React.createElement(CardActionArea, { sx: {
                            display: 'flex',
                            flexDirection: 'row'
                        } },
                        React.createElement("div", { className: 'projectCards__img' },
                            React.createElement(CardMedia, { component: "img", sx: {
                                    objectFit: 'cover',
                                    verticalAlign: 'middle',
                                    height: '100%',
                                    width: '100%',
                                    filter: card.tag === 'Inactive' ? "grayscale(100%)" : "none"
                                }, image: card.icon, alt: "Project Image" })),
                        React.createElement(CardContent, { sx: {
                                padding: "8px",
                                flex: 1
                            } },
                            React.createElement(Typography, { sx: {
                                    fontFamily: "inherit",
                                    fontWeight: 600,
                                    fontSize: "13px",
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2
                                }, variant: 'body1' }, card.name),
                            React.createElement(Typography, { sx: {
                                    fontFamily: "inherit",
                                    fontWeight: 400,
                                    fontSize: "11px",
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2
                                }, variant: "body1", color: "text.secondary" }, card.description)))))));
    })));
};
export default ProjectCardsNew;
