var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { NavLink } from "react-router-dom";
var NavDrawerNew = /** @class */ (function (_super) {
    __extends(NavDrawerNew, _super);
    function NavDrawerNew() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isDrawerOpened: false
        };
        _this.toggleDrawer = function (open) {
            return function (event) {
                if (event.type === 'keydown' &&
                    (event.key === 'Tab' ||
                        event.key === 'Shift')) {
                    return;
                }
                _this.setState({ isDrawerOpened: open });
            };
        };
        _this.list = function () { return (React.createElement(Box, { sx: { width: "auto", fontWeight: "100" }, role: "presentation", onClick: _this.toggleDrawer(false), onKeyDown: _this.toggleDrawer(false) },
            React.createElement(List, null,
                React.createElement(NavLink, { className: "navDrawerNew__link", to: "/faqs/" },
                    React.createElement(ListItemButton, null,
                        React.createElement(ListItemText, { sx: {
                                '& .MuiTypography-root': {
                                    fontFamily: "inherit",
                                    fontWeight: 600,
                                    fontSize: "20px"
                                }
                            } }, "FAQ"))),
                React.createElement("a", { className: "navDrawerNew__link", href: "https://cargroup.enterprise.slack.com/archives/C06E6B6CHFU", target: "_blank" },
                    React.createElement(ListItemButton, null,
                        React.createElement(ListItemText, { sx: {
                                '& .MuiTypography-root': {
                                    fontFamily: "inherit",
                                    fontWeight: 600,
                                    fontSize: "20px"
                                }
                            } }, "#slack-channel")))))); };
        return _this;
    }
    NavDrawerNew.prototype.render = function () {
        return (React.createElement("div", { className: "navDrawerNew" },
            React.createElement(Button, { onClick: this.toggleDrawer(true) },
                React.createElement("img", { src: require("../../images/nav-bar.svg").default })),
            React.createElement(Drawer, { anchor: "top", open: this.state.isDrawerOpened, onClose: this.toggleDrawer(false) }, this.list())));
    };
    return NavDrawerNew;
}(React.PureComponent));
export default NavDrawerNew;
