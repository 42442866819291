import React from "react";
import ReactDOM from "react-dom";
import Hackathon from "./containers/Hackathon";
import CustomEventPolyfill from "./utils/CustomEventPolyfill";
import EnforceTrailingSlash from "./utils/EnforceTrailingSlash";
require("../scss/index.scss");
CustomEventPolyfill();
EnforceTrailingSlash();
var el = document.getElementById("hackathon");
el && ReactDOM.render(React.createElement(Hackathon, null), el);
// el && ReactDOM.render(<HackathonNew />, el);
