var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { Link } from "react-router-dom";
import { InvitationTypes } from "../models";
import RouteBuilder from "../utils/RouteBuilder";
import { getOrganisationById, getEventByIdAndOrgId, getProjectByIdAndEventIdAndOrgId, acceptInvitation, rejectInvitation } from "../utils/CommonRequests";
import { FailNotification, SuccessNotification } from "../utils/NotificationEvent";
import { Skeleton } from ".";
var UserInvitations = /** @class */ (function (_super) {
    __extends(UserInvitations, _super);
    function UserInvitations() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            organisation: null,
            event: null,
            project: null,
            details: null,
            actioned: false
        };
        _this.accept = function () {
            acceptInvitation(_this.props.invitation.Id)
                .then(function (res) {
                SuccessNotification("Invitation accepted. Welcome to the ".concat(InvitationTypes[_this.props.invitation.Type], "."));
                _this.setState({ actioned: true });
            })
                .catch(function (res) { return FailNotification("Error accepting invite."); });
        };
        _this.reject = function () {
            rejectInvitation(_this.props.invitation.Id)
                .then(function (res) {
                SuccessNotification("Invitation rejected. What a shame.");
                _this.setState({ actioned: true });
            })
                .catch(function (res) { return FailNotification("Error rejecting invite."); });
        };
        return _this;
    }
    UserInvitations.prototype.componentDidMount = function () {
        this.fetchAllData();
    };
    UserInvitations.prototype.fetchAllData = function () {
        var _this = this;
        var _a = this.props.invitation, Organisation = _a.Organisation, Event = _a.Event, Project = _a.Project, Type = _a.Type;
        var org = Organisation ? getOrganisationById(Organisation) : Promise.resolve(null);
        var event = Organisation && Event ? getEventByIdAndOrgId(Organisation, Event) : Promise.resolve(null);
        var project = Organisation && Event && Project
            ? getProjectByIdAndEventIdAndOrgId(Organisation, Event, Project)
            : Promise.resolve(null);
        Promise.all([org, event, project])
            .then(function (res) {
            var state = __assign({}, _this.state);
            state.organisation = res[0];
            state.event = res[1];
            state.project = res[2];
            if (Type === InvitationTypes.Organisation) {
                state.details = res[0];
            }
            else if (Type === InvitationTypes.Event) {
                state.details = res[1];
            }
            else {
                state.details = res[2];
            }
            _this.setState(state);
        })
            .catch(function () {
            _this.props.onError();
            // FailNotification("Error loading invite information.");
        });
    };
    UserInvitations.prototype.render = function () {
        var invitation = this.props.invitation;
        var _a = this.state, details = _a.details, organisation = _a.organisation, event = _a.event, project = _a.project;
        if (this.state.actioned) {
            return null;
        }
        if (details === null)
            return (React.createElement("li", { className: "invites__invite" },
                React.createElement(Skeleton, { width: 75 })));
        return (React.createElement("li", { className: "invites__invite" },
            React.createElement("div", { className: "invites__name" },
                React.createElement(Link, { to: RouteBuilder("", organisation ? organisation.Id : "", event ? event.Id : "", project ? project.Id : "") }, details.Name),
                React.createElement("span", { className: "invites__type" }, InvitationTypes[invitation.Type])),
            React.createElement("div", { className: "invite__actions" },
                React.createElement("button", { className: "btn btn-sm btn-success ml-3", onClick: this.accept }, "Count me in!"),
                React.createElement("button", { className: "btn btn-sm btn-danger ml-1", onClick: this.reject }, "No thanks"))));
    };
    return UserInvitations;
}(React.PureComponent));
export default UserInvitations;
