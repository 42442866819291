var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { PageHeader, MarkdownRender, EventList, MarkdownSkeleton, InviteBar, ControlledTabs } from "../components";
import ApiRoutes from "../utils/ApiRoutes";
import { FailNotification } from "../utils/NotificationEvent";
import { Link } from "react-router-dom";
import { AxiosStrict } from "../utils/Axios";
var Organisation = /** @class */ (function (_super) {
    __extends(Organisation, _super);
    function Organisation() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            details: null,
            userPermissions: null
        };
        return _this;
    }
    Object.defineProperty(Organisation.prototype, "params", {
        get: function () {
            return this.props.match.params;
        },
        enumerable: false,
        configurable: true
    });
    Organisation.prototype.componentDidMount = function () {
        document.title = "Organisation | Hackathon Portal";
        this.fetchOrgData();
    };
    Organisation.prototype.fetchPermissions = function () {
        var _this = this;
        AxiosStrict()
            .get("".concat(ApiRoutes.UserOrganisations, "/").concat(this.params.orgId))
            .then(function (res) {
            if (res.status === 200) {
                document.title = "".concat(res.data.Organisation.Name, " | Hackathon Portal");
                _this.setState({ userPermissions: res.data.Organisation });
            }
        })
            .catch(function (err) { return FailNotification("Error loading user permissions."); });
    };
    Organisation.prototype.fetchOrgData = function () {
        var _this = this;
        AxiosStrict()
            .get("".concat(ApiRoutes.Organisation, "/").concat(this.params.orgId))
            .then(function (res) {
            if (res.status === 200) {
                _this.setState({ details: res.data.Organisation });
                _this.fetchPermissions();
            }
        });
    };
    Organisation.prototype.permissions = function () {
        var userPermissions = this.state.userPermissions;
        var permissions = {
            edit: false
        };
        if (userPermissions) {
            var Permissions_1 = userPermissions.Permissions, Roles = userPermissions.Roles;
            permissions.edit = Permissions_1.includes("organisation_edit");
        }
        return permissions;
    };
    Organisation.prototype.render = function () {
        var details = this.state.details;
        var perms = this.permissions();
        var tabs = [
            {
                label: "Overview",
                content: this.state.details ? React.createElement(MarkdownRender, { raw: this.state.details.Content }) : React.createElement(MarkdownSkeleton, null)
            },
            { label: "Events", content: React.createElement(EventList, { events: details ? details.Events : null }) }
        ];
        var tabListExtra = (React.createElement("div", { className: "react-tabs__tab ml-auto" }, perms.edit && (React.createElement(Link, { to: "./edit/", className: "btn btn-sm btn-success" }, "Edit"))));
        return (React.createElement("div", null,
            React.createElement(PageHeader, { title: details !== null ? details.Name : null, logo: details !== null ? details.LogoUrl : null }),
            React.createElement(InviteBar, __assign({}, this.params)),
            React.createElement("div", { className: "container" },
                React.createElement(ControlledTabs, { tabs: tabs, tabListExtra: tabListExtra }))));
    };
    return Organisation;
}(React.PureComponent));
export default Organisation;
