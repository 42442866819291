export var VotingState;
(function (VotingState) {
    VotingState["Open"] = "Open";
    VotingState["Closed"] = "Closed";
    VotingState["Completed"] = "Completed";
})(VotingState || (VotingState = {}));
export var sampleVotingData = {
    Code: "Success",
    Report: {
        EventId: "hackathonx-treme",
        OrganisationId: "carsales",
        Status: VotingState["Completed"],
        Start: "2019-06-20T06:26:26Z",
        End: "2019-07-29T06:26:26Z",
        Results: [
            {
                ProjectId: "markdown-in-comments-support",
                Name: "Markdown in Comments Support",
                LogoUrl: "https://i.imgur.com/4p5GPaB.gif",
                Description: "https://i.imgur.com/4p5GPaB.gif",
                Votes: 2,
                Rank: 1
            },
            {
                ProjectId: "a-newbie-yep",
                Name: "A newbie yep",
                LogoUrl: "https://hackgorilla.pxcrush.net/ugc/35b3gr7kdnor3kmcbrs9ffa7s.png",
                Description: "",
                Votes: 2,
                Rank: 1
            },
            { ProjectId: "mad-skillz", Name: "Mad skillz", LogoUrl: "", Description: "a", Votes: 1, Rank: 3 },
            { ProjectId: "invite-test", Name: "Invite Test", LogoUrl: "", Description: "", Votes: 1, Rank: 3 },
            {
                ProjectId: "dog-dingo-dog-dog",
                Name: "dog dingo dog dog",
                LogoUrl: "https://hackgorilla.pxcrush.net/ugc/24pw4opyyl1sy4rz5zof76fds.png",
                Description: "",
                Votes: 1,
                Rank: 3
            },
            { ProjectId: "doggo-town", Name: "Doggo town", LogoUrl: null, Description: "", Votes: 1, Rank: 3 },
            { ProjectId: "build-o-2", Name: "Build-o 2", LogoUrl: null, Description: "", Votes: 0, Rank: 7 },
            { ProjectId: "catto", Name: "catto", LogoUrl: null, Description: "", Votes: 0, Rank: 7 },
            { ProjectId: "brand-spanking-new", Name: "Brand spanking New", LogoUrl: "", Description: "", Votes: 0, Rank: 7 },
            {
                ProjectId: "dingo-dog",
                Name: "dingo dog",
                LogoUrl: "https://hackgorilla.pxcrush.net/ugc/bsebt4fjhj6cep0ntgfgij32d.jpg",
                Description: "",
                Votes: 0,
                Rank: 7
            },
            {
                ProjectId: "dog-dog-dog-doggo",
                Name: "dog dog dog doggo",
                LogoUrl: "https://hackgorilla.pxcrush.net/ugc/24pw4opyyl1sy4rz5zof76fds.png",
                Description: "",
                Votes: 0,
                Rank: 7
            },
            {
                ProjectId: "miso-soup",
                Name: "miso soup",
                LogoUrl: "https://hackgorilla.pxcrush.net/ugc/24pw4opyyl1sy4rz5zof76fds.png",
                Description: "",
                Votes: 0,
                Rank: 7
            },
            {
                ProjectId: "doogle",
                Name: "doogle",
                LogoUrl: "https://hackgorilla.pxcrush.net/ugc/24pw4opyyl1sy4rz5zof76fds.png",
                Description: "",
                Votes: 0,
                Rank: 7
            }
        ]
    }
};
