var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { UserManager, WebStorageStateStore } from "oidc-client";
import getAbsolutePath from "./getAbsolutePath";
import cookieName from "./cookieName";
import Cookies from "js-cookie";
var REDIRECT_URL_KEY = "login_redirect_url";
var host = location.protocol + "//" + location.host;
var AuthService = /** @class */ (function () {
    function AuthService() {
        var settings = {
            authority: "https://login.microsoftonline.com/common/v2.0/",
            client_id: "b84fa514-3508-4920-8514-22f8ff44c74e",
            redirect_uri: host + "/auth",
            silent_redirect_uri: host + "/auth",
            post_logout_redirect_uri: host + "/",
            response_type: "id_token",
            scope: "openid profile email",
            loadUserInfo: false,
            userStore: new WebStorageStateStore({ store: window.localStorage })
        };
        this.client = new UserManager(settings);
    }
    AuthService.prototype.login = function (redirectTo) {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.setUseOnceRedirectUrl(redirectTo);
                        return [4 /*yield*/, this.client.signinRedirect()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        err_1 = _a.sent();
                        console.log(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.loginCallback = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.client.signinRedirectCallback()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        console.log(err_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.apiCallback = function () {
        var uri = this.getUseOnceRedirectUrl();
        return { redirect_uri: uri };
    };
    AuthService.prototype.renewToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    return [2 /*return*/, this.client.signinSilent()];
                }
                catch (_b) { }
                return [2 /*return*/];
            });
        });
    };
    AuthService.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.client.signoutRedirect()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        err_3 = _a.sent();
                        console.log(err_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.setUseOnceRedirectUrl = function (url) {
        if (window.sessionStorage) {
            var absoluteUrl = getAbsolutePath(url || location.href);
            window.sessionStorage.setItem(REDIRECT_URL_KEY, absoluteUrl);
        }
    };
    AuthService.prototype.getUseOnceRedirectUrl = function () {
        var redirectUrl = null;
        if (window.sessionStorage) {
            redirectUrl = window.sessionStorage.getItem(REDIRECT_URL_KEY);
            window.sessionStorage.removeItem(REDIRECT_URL_KEY);
        }
        return redirectUrl || "/";
    };
    AuthService.prototype.authSuccess = function (token) {
        Cookies.set(cookieName, { token: token });
        window.history.replaceState({}, "", window.location.href.split("?")[0]);
    };
    AuthService.prototype.signOut = function () {
        Cookies.remove(cookieName);
        var evt = new CustomEvent("clearUserDetails", {
            detail: {}
        });
        document.body.dispatchEvent(evt);
    };
    return AuthService;
}());
export default AuthService;
