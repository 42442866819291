var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Skeleton from "./Skeleton";
var CountdownTimer = /** @class */ (function (_super) {
    __extends(CountdownTimer, _super);
    function CountdownTimer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.zeroTime = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
        _this.ticker = null;
        _this.state = {
            tick: 0,
            timeUntil: __assign({}, _this.zeroTime)
        };
        return _this;
    }
    CountdownTimer.prototype.componentWillMount = function () {
        var _this = this;
        this.ticker = setInterval(function () {
            var timeUntil = _this.props.dateTime ? _this.getTimeUntil(new Date(_this.props.dateTime)) : _this.zeroTime;
            _this.setState({ timeUntil: timeUntil });
        }, 1000);
    };
    CountdownTimer.prototype.componentWillUnmount = function () {
        clearInterval(this.ticker);
    };
    CountdownTimer.prototype.getTimeUntil = function (d1) {
        var res = __assign({}, this.zeroTime);
        var date1 = d1.getTime();
        var date2 = new Date().getTime();
        if (date2 > date1) {
            this.props.onZero && this.props.onZero();
            clearInterval(this.ticker);
            return res;
        }
        var diff = (date2 - date1) / 1000;
        diff = Math.abs(Math.floor(diff));
        res.days = Math.floor(diff / (24 * 60 * 60));
        var secondsRemain = diff - res.days * 24 * 60 * 60;
        res.hours = Math.floor(secondsRemain / (60 * 60));
        secondsRemain = secondsRemain - res.hours * 60 * 60;
        res.minutes = Math.floor(secondsRemain / 60);
        secondsRemain = secondsRemain - res.minutes * 60;
        res.seconds = secondsRemain;
        if (res.days > 99)
            res.days = 99;
        return res;
    };
    CountdownTimer.prototype.render = function () {
        var _a = this.props, dateTime = _a.dateTime, message = _a.message, abbreviated = _a.abbreviated;
        var time = dateTime ? this.getTimeUntil(new Date(dateTime)) : this.zeroTime;
        return (React.createElement("div", null,
            message && React.createElement("div", { className: "timer__message" }, message),
            dateTime ? (React.createElement("div", { className: "timer" },
                React.createElement("div", { className: "timer__step timer__step--days" },
                    React.createElement("div", { className: "timer__count" }, time.days.toString().padStart(2, "0")),
                    React.createElement("span", { className: "timer__label" }, "Days")),
                React.createElement("div", { className: "timer__step timer__step--hours" },
                    React.createElement("div", { className: "timer__count" }, time.hours.toString().padStart(2, "0")),
                    React.createElement("span", { className: "timer__label" }, abbreviated ? "Hrs" : "Hours")),
                React.createElement("div", { className: "timer__step timer__step--minutes" },
                    React.createElement("div", { className: "timer__count" }, time.minutes.toString().padStart(2, "0")),
                    React.createElement("span", { className: "timer__label" }, abbreviated ? "Mins" : "Minutes")),
                React.createElement("div", { className: "timer__step timer__step--seconds" },
                    React.createElement("div", { className: "timer__count" }, time.seconds.toString().padStart(2, "0")),
                    React.createElement("span", { className: "timer__label" }, abbreviated ? "Secs" : "Seconds")))) : (React.createElement("div", { className: "timer" },
                React.createElement(Skeleton, { width: 33, height: 60 })))));
    };
    return CountdownTimer;
}(React.PureComponent));
export default CountdownTimer;
