var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { Avatar, UserOrganisationList, UserProjectList, UserEventsList, UserInvitationsList, UserVoteList } from "../components";
import DashboardVideoPlayerNew from "../components/DashboardVideoPlayerNew";
var Dashboard = /** @class */ (function (_super) {
    __extends(Dashboard, _super);
    function Dashboard() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {};
        return _this;
    }
    Dashboard.prototype.componentDidMount = function () {
        document.title = "Dashboard | Hackathon Portal";
    };
    Dashboard.prototype.render = function () {
        var user = this.props.user;
        return (React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-12 col-sm-4 col-lg-3 mb-5" },
                    React.createElement(Avatar, { user: user || null }),
                    React.createElement(UserOrganisationList, null)),
                React.createElement("div", { className: "col-12 col-sm-8 col-lg-9" },
                    React.createElement("h1", { className: "h1" }, "Welcome to the Hackathon Portal"),
                    React.createElement("div", { className: "mb-5" },
                        React.createElement("h6", { className: "h5" }, "About Hackathon"),
                        React.createElement("p", { className: "small" }, "Here, we celebrate innovation and collaboration. Whether you're a coding maestro or a creative thinker, everyone is welcome to join our exciting events. It's not just for our tech team; it's a space for all to unleash their creativity and make a real impact."),
                        React.createElement(DashboardVideoPlayerNew, null)),
                    React.createElement("p", null, "See your upcoming projects and events listed below:"),
                    React.createElement("p", null, "\u00A0"),
                    React.createElement("h2", { className: "h5" }, "You've Voted On"),
                    React.createElement("div", { className: "mb-5" },
                        React.createElement(UserVoteList, null)),
                    React.createElement("h2", { className: "h5" }, "Pending Invitations"),
                    React.createElement("div", { className: "mb-5" },
                        React.createElement(UserInvitationsList, null)),
                    React.createElement("h2", { className: "h5" }, "Your Projects"),
                    React.createElement("div", { className: "mb-5" },
                        React.createElement(UserProjectList, null)),
                    React.createElement("h2", { className: "h5" }, "Upcoming / Current Events"),
                    React.createElement("div", { className: "mb-5" },
                        React.createElement(UserEventsList, { status: "Upcoming" })),
                    React.createElement("h2", { className: "h5" }, "Past Events"),
                    React.createElement("div", { className: "mb-5" },
                        React.createElement(UserEventsList, { status: "Past" }))))));
    };
    return Dashboard;
}(React.PureComponent));
export default Dashboard;
