var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import ApiRoutes from "../utils/ApiRoutes";
import { AxiosFlexible } from "../utils/Axios";
import RouteBuilder from "../utils/RouteBuilder";
import { Cards } from "./";
var UserEventsList = /** @class */ (function (_super) {
    __extends(UserEventsList, _super);
    function UserEventsList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isMounted = false;
        _this.state = {
            events: null,
            hasError: false
        };
        return _this;
    }
    UserEventsList.prototype.componentDidMount = function () {
        this._isMounted = true;
        this.fetchEvents();
    };
    UserEventsList.prototype.componentWillUnmount = function () {
        this._isMounted = false;
    };
    UserEventsList.prototype.setState = function (state) {
        this._isMounted && _super.prototype.setState.call(this, state);
    };
    UserEventsList.prototype.fetchEvents = function () {
        var _this = this;
        AxiosFlexible()
            .get(ApiRoutes.UserEvents)
            .then(function (res) {
            if (res.status) {
                _this.setState({ events: res.data.Events });
            }
            else {
                _this.setState({ hasError: true });
            }
        })
            .catch(function (err) {
            _this.setState({ hasError: true });
        });
    };
    UserEventsList.prototype.render = function () {
        var _a = this.state, events = _a.events, hasError = _a.hasError;
        var status = this.props.status;
        if (hasError) {
            return (React.createElement("p", { className: "small" },
                React.createElement("em", null,
                    "No events found.",
                    React.createElement("sup", null, "*"))));
        }
        return (React.createElement(Cards, { emptyMessage: "No events found.", items: events
                ? events
                    .filter(function (ev) { return ev.Status == status; })
                    .map(function (event) {
                    return {
                        id: event.Id,
                        name: event.Name,
                        icon: event.LogoUrl || require("../../images/icon-event.png"),
                        link: RouteBuilder("", event.OrganisationId, event.Id),
                        description: event.Description
                    };
                })
                : null }));
    };
    return UserEventsList;
}(React.PureComponent));
export default UserEventsList;
