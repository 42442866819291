import React from "react";
import { Link } from "react-router-dom";
var ErrorPage = function (props) {
    document.title = "Error (".concat(props.status, ") | Hackathon Portal");
    var title = "";
    var status = props.status;
    switch (status) {
        case "400":
            title = "400: Bad Request";
            break;
        case "401":
            title = "401: Unauthorised";
            break;
        case "403":
            title = "403: Forbidden";
            break;
        case "404":
            title = "404: Page Not Found";
            break;
        case "500":
            title = "500: Server Error";
            break;
        default:
            title = status;
            break;
    }
    return (React.createElement("div", { className: "errorPage" },
        React.createElement("img", { className: "errorPage__img", src: require("../../images/Gorilla.png").default, width: "180", height: "180" }),
        React.createElement("h3", { className: "errorPage__title h3" }, title),
        React.createElement("p", null,
            React.createElement(Link, { to: "/dashboard/" }, "Back to dashboard"))));
};
export default ErrorPage;
