var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { FAQListNew } from "../components";
var FAQsPageNew = /** @class */ (function (_super) {
    __extends(FAQsPageNew, _super);
    function FAQsPageNew() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.faqs = require("../data/faq-content");
        return _this;
    }
    FAQsPageNew.prototype.componentDidMount = function () {
        document.title = "FAQs | Hackathon Portal";
    };
    FAQsPageNew.prototype.render = function () {
        return (React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-12 col-sm-12 col-lg-3 faqListNew__logo" },
                    React.createElement("img", { src: require("../../images/people.svg").default })),
                React.createElement("div", { className: "col-12 col-sm-12 col-lg-9" },
                    React.createElement(FAQListNew, { faqs: this.faqs })))));
    };
    return FAQsPageNew;
}(React.PureComponent));
export default FAQsPageNew;
