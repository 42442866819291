var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { AxiosStrict, AxiosFlexible } from "../utils/Axios";
import { Link } from "react-router-dom";
import ApiRoutes from "../utils/ApiRoutes";
import { FailNotification } from "../utils/NotificationEvent";
var InvitationAccept = /** @class */ (function (_super) {
    __extends(InvitationAccept, _super);
    function InvitationAccept() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            inviteSuccess: null,
            invite: null
        };
        return _this;
    }
    Object.defineProperty(InvitationAccept.prototype, "params", {
        get: function () {
            return this.props.match.params;
        },
        enumerable: false,
        configurable: true
    });
    InvitationAccept.prototype.componentDidMount = function () {
        document.title = "Accept Invitation | Hackathon Portal";
        this.fetchInvite();
    };
    InvitationAccept.prototype.fetchInvite = function () {
        var _this = this;
        AxiosStrict()
            .get("".concat(ApiRoutes.UserInvitations, "/").concat(this.params.invitationId))
            .then(function (res) {
            if (res.status === 200) {
                _this.setState({ invite: res.data.Invitation }, function () {
                    _this.acceptInvitation();
                });
            }
        })
            .catch(function (err) {
            _this.props.history.push("/error/404/?from=".concat(window.location.href));
        });
    };
    InvitationAccept.prototype.componentWillUnmount = function () {
        if (typeof this.redirectTimeout === "undefined") {
            clearTimeout(this.redirectTimeout);
        }
    };
    InvitationAccept.prototype.acceptInvitation = function () {
        var _this = this;
        AxiosFlexible()
            .post("".concat(ApiRoutes.UserInvitations, "/").concat(this.params.invitationId, "/accept"), {})
            .then(function (res) {
            if (res.status === 200) {
                _this.setState({ inviteSuccess: true });
                // SuccessNotification("Invite accepted.");
                setTimeout(function () {
                    _this.props.history.push("/dashboard/");
                }, 5000);
            }
            else {
                FailNotification("Unable to accept invitation.", 5000);
                _this.setState({ inviteSuccess: false });
            }
        })
            .catch(function (err) {
            FailNotification("Unable to accept invitation.", 5000);
            _this.setState({ inviteSuccess: false });
        });
    };
    InvitationAccept.prototype.render = function () {
        var _a = this.state, inviteSuccess = _a.inviteSuccess, invite = _a.invite;
        return (React.createElement("div", null,
            React.createElement("div", { className: "container" },
                inviteSuccess === true && (React.createElement("div", null,
                    React.createElement("h2", { className: "h2" }, "Welcome to the team!"),
                    React.createElement("p", null,
                        "You've accepted the invitation and are now a part of ",
                        React.createElement("strong", null, invite.Name)),
                    React.createElement("p", null,
                        "You will be redirected to the dashboard page in 5 seconds, ",
                        React.createElement(Link, { to: "/dashboard/" }, "click here"),
                        " to go immediately."))),
                inviteSuccess === false && (React.createElement("div", null,
                    React.createElement("h2", { className: "h2" }, "Oops, something went wrong."),
                    React.createElement("p", null, "Please check your invitation link and try again."))),
                inviteSuccess === null && (React.createElement("div", null,
                    React.createElement("h2", { className: "h2" }, "Accepting Invitation"),
                    React.createElement("p", null, "You're invitation will now be accepted. Please wait for confirmation."))))));
    };
    return InvitationAccept;
}(React.PureComponent));
export default InvitationAccept;
