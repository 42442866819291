export var ProjectJoinOptions;
(function (ProjectJoinOptions) {
    ProjectJoinOptions["InviteOnly"] = "InviteOnly";
    ProjectJoinOptions["Anybody"] = "Anybody";
})(ProjectJoinOptions || (ProjectJoinOptions = {}));
export var ProjectStatus;
(function (ProjectStatus) {
    ProjectStatus["Pending"] = "Pending";
    ProjectStatus["Active"] = "Active";
    ProjectStatus["Inactive"] = "Inactive";
})(ProjectStatus || (ProjectStatus = {}));
