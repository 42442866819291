var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { PublicRoute, PrivateRoute, Dashboard, Organisation, Profile, Project, Event, ErrorPage, ProjectEditor, InvitationAccept, Credits, SignOut, EventEditor, IMustDance, ProjectVoting, CompleteLogin, FAQsPageNew, } from "./";
import { Notification, MediaLibrary, NavDrawerNew, AccountNavNew } from "../components";
import { AxiosFlexible } from "../utils/Axios";
import ApiRoutes from "../utils/ApiRoutes";
import { Router, Redirect, Switch } from "react-router";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import EnforceTrailingSlash from "../utils/EnforceTrailingSlash";
import OrganisationEditor from "./OrganisationEditor";
import KonamiCode from "konami-code";
import EventResults from "./EventResults";
import VideoPlayer from "../components/VideoPlayer";
var Hackathon = /** @class */ (function (_super) {
    __extends(Hackathon, _super);
    function Hackathon(props) {
        var _this = _super.call(this, props) || this;
        _this.history = createBrowserHistory();
        _this.state = {
            user: null,
            isDancing: false
        };
        _this.fetchUserData = function () {
            AxiosFlexible()
                .get(ApiRoutes.UserDetails)
                .then(function (res) {
                if (res.status === 200) {
                    _this.setState({ user: res.data.User });
                }
            })
                .catch(function (err) {
                // FailNotification("Error loading user.");
            });
        };
        return _this;
    }
    Hackathon.prototype.componentDidMount = function () {
        var _this = this;
        new KonamiCode().listen(function () { return _this.setState({ isDancing: true }); });
        document.body.addEventListener("clearUserDetails", function () { return _this.setState({ user: null }); });
        document.body.addEventListener("getUserDetails", function (e) { return _this.fetchUserData(); });
        this.history.listen(function (h) {
            EnforceTrailingSlash();
            if (typeof window["ga"] !== "undefined") {
                window["ga"]("set", "page", location.pathname + location.search);
                window["ga"]("send", "pageview");
            }
        });
    };
    Hackathon.prototype.render = function () {
        var _this = this;
        var _a = this.state, user = _a.user, isDancing = _a.isDancing;
        return (React.createElement(Router, { history: this.history },
            React.createElement("header", { className: "siteHeader" },
                React.createElement(NavDrawerNew, { isDrawerOpened: false }),
                React.createElement(Link, { to: "/dashboard/", className: "siteHeader__logo" },
                    React.createElement("img", { src: require("../../images/logo.png").default })),
                React.createElement(AccountNavNew, { history: this.history, user: user })),
            React.createElement("section", { className: "page" },
                React.createElement(Switch, null,
                    React.createElement(PublicRoute, { exact: true, path: "/", component: function (props) { return React.createElement(Redirect, __assign({}, props, { to: "/dashboard/".concat(props.location.search) })); } }),
                    React.createElement(PublicRoute, { path: "/credits/", component: Credits }),
                    React.createElement(PublicRoute, { path: "/auth", component: function (props) { return React.createElement(CompleteLogin, __assign({}, props)); } }),
                    React.createElement(PublicRoute, { path: "/signout/", component: SignOut }),
                    React.createElement(PublicRoute, { path: "/error/:status/", component: function (props) { return React.createElement(ErrorPage, __assign({}, props, { status: props.match.params.status })); } }),
                    React.createElement(PrivateRoute, { path: "/dashboard/", render: function (props) { return React.createElement(Dashboard, __assign({}, props, { user: user })); } }),
                    React.createElement(PrivateRoute, { path: "/faqs/", render: function () { return React.createElement(FAQsPageNew, null); } }),
                    React.createElement(PrivateRoute, { path: "/profile/", component: function (props) { return React.createElement(Profile, __assign({}, props, { user: user, updateUser: _this.fetchUserData })); } }),
                    React.createElement(PrivateRoute, { path: "/invitations/:invitationId", component: function (props) { return React.createElement(InvitationAccept, __assign({}, props)); } }),
                    React.createElement(PrivateRoute, { exact: true, path: "/:orgId/edit", component: OrganisationEditor }),
                    React.createElement(PrivateRoute, { exact: true, path: "/:orgId/:eventId/results", component: EventResults }),
                    React.createElement(PrivateRoute, { exact: true, path: "/:orgId/:eventId/edit", component: EventEditor }),
                    React.createElement(PrivateRoute, { path: "/:orgId/:eventId/:projectId/vote/", component: function (props) { return React.createElement(ProjectVoting, __assign({}, props)); } }),
                    React.createElement(PrivateRoute, { path: "/:orgId/:eventId/:projectId/edit/", component: function (props) { return React.createElement(ProjectEditor, __assign({}, props, { isNew: false })); } }),
                    React.createElement(PrivateRoute, { path: "/:orgId/:eventId/create/", component: function (props) { return React.createElement(ProjectEditor, __assign({}, props, { isNew: true })); } }),
                    React.createElement(PrivateRoute, { path: "/:orgId/:eventId/:projectId/", component: Project }),
                    React.createElement(PrivateRoute, { path: "/:orgId/:eventId", component: Event }),
                    React.createElement(PrivateRoute, { path: "/:orgId", component: Organisation }),
                    React.createElement(PublicRoute, { path: "*", component: function (props) { return React.createElement(Redirect, __assign({}, props, { to: "/error/404" })); } }))),
            React.createElement(Notification, null),
            React.createElement(MediaLibrary, null),
            React.createElement(VideoPlayer, null),
            isDancing && React.createElement(IMustDance, { close: function () { return _this.setState({ isDancing: false }); } })));
    };
    return Hackathon;
}(React.PureComponent));
export default Hackathon;
